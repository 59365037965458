<template>
  <div class="header">
    <h3>Cart</h3>
  </div>
  <div v-if="cartList.length > 0" class="container-fluid">
    <div class="card">
      <div class="card-body row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="tips">Warehouse Sections:</div>
          <div class="warehouse" v-for="(info, item) in cartList">
            <span class="itemtotal" @click="clickwarehouse(item)">{{ info.warehouse }}<span>({{ info.itemTotal
            }})</span></span>
          </div>
          <div class="warehouse-area" v-for="(index, groups) in cartList">
            <div class="card-body">
              <h5 class="card-title">Warehouse:<span ref="warehoseTarget">{{ index.warehouse }}</span> </h5>
              <h6 class="card-subtitle mb-2 text-muted">
                <div @click="quick_del(index)" class="quickDelete">
                  <i class="icofont icofont-ui-delete"></i>
                  <i class="icofont icofont-caret-down"></i>
                  <span>QUICK DELETE</span>
                </div>
              </h6>
              <p class="card-text">
              <div class="items_text">items:<span>{{ index.itemTotal }}</span></div>
              </p>
              <div class="offer-status-navigation">
                <div v-for="(k, value) in index.group" class="offer-status">
                  <div class="reject" @click="scrollToTarget(value)">
                    <span>{{ k.Name }}<span>({{ k.itemCountFormatted }})</span></span>
                    <input class="form-check-input " type="radio" id="radioNoLabel1" value="">
                  </div>
                </div>
              </div>
              <div class="Warehousebody">
                <div class="cartdatas">
                  <div class="checkoutItems">
                    <div class="items"> Items Selected for Checkout:</div>
                    <div class="num">{{ index.checkoutTotal }}</div>
                  </div>
                  <div class="checkoutItems">
                    <div class="items">Qty:</div>
                    <div class="num">{{ index.quantity }}</div>
                  </div>
                  <div class="checkoutItems">
                    <div class="items">Subtotal for W04-BBY:</div>
                    <div class="num">${{ index.subTotal }}</div>

                  </div>
                </div>
                <div class="col-sm-12 col-lg-12 col-xl-12" v-for="(k, value) in index.group">
                  <div class="checkoutbtn" v-if="k.Status == 1">
                    <a :class="['btn', 'btn-primary', 'bbtn-lgtn']" @click="convert">Checkout</a>
                  </div>
                  <div v-else></div>
                </div>
                <hr class="hr">
              </div>
            </div>
            <div class="cardgrops" v-for="(k, value) in index.group">
              <div @click="card_pop(k)">
                <div class="subtitles">
                  <i :class="[k.show ? 'icofont-curved-up' : 'icofont-curved-down', 'icofont']"></i>
                  <span class="rejected" ref="scrollTarget">{{ k.Name }}<span>({{ selectedCount }}/{{ k.itemCountFormatted
                  }})</span></span>
                  <span class="slected_text">Items selected for checkout will be at Stock List Price</span>
                </div>
              </div>
              <div v-if="k.show" v-for="(data, worth) in k.items">
                <div class="datainfo">
                  <div class="description">
                    <input :checked="data.readyToCheckout" type="checkbox" @change="calculateoffer" disabled="true">
                    <span class="dec_text">{{ data.itemDescription }}</span>
                  </div>
                  <div v-if="k.Name == 'Approved' || k.Name == 'Rejected'|| k.Name == 'Expired ' || k.Name == 'Closed'" class="quanty">
                    <div class="qty">
                      Offer Qty:
                      <span>{{ data.offerQuantity }}</span>
                    </div>
                    <div class="price">Offer price:
                      <span>${{ data.offerPrice }}</span>
                    </div>
                  </div>
                  <div class="outStock" v-else-if="data.offerStatusLabel == 'Out of Stock'">--</div>
                  <div v-else class="quanty">
                    <div class="qty">
                      Offer Qty:
                      <input type="number" :min="1" :max="data.offerQuantity" style="text-align:center"
                        v-model="data.offerQuantity" />
                      <!-- <span>{{ data.offerQuantity }}</span> -->
                    </div>
                    <div class="price">Offer price:
                      <input type="number" style="text-align:center" step="1" :min="1" 
                        v-model="data.offerPrice" />
                      <!-- <span>${{ data.offerPrice }}</span> -->
                    </div>
                    <button class="query_btn" @click="updateOffer(data)">√</button>
                  </div>


                  <div class="delete_querybtn">
                    <i class="icofont icofont-ui-delete" @click="deleteOffer(data)"></i>
                  </div>
                </div>
                <div class="dataErroData" v-if="message">{{ message }}</div>
                <div class="databody">
                  <div class="itemid">item #<span>{{ data.itemNumber }}</span></div>
                  <div class="numinfo">
                    <div class="avails" v-if="data.offerStatusLabel == 'Out of Stock'">Avail: <div>__</div>
                    </div>
                    <div class="avails" v-else>Avail: <div>{{ data.quantityAvailable }}</div>
                    </div>
                    <div class="prices">Price: <div>${{ data.actualListPrice }}</div>
                    </div>
                    <!-- <div class="makeOffer" @click="makeOffer(data)">or MAKE AN OFFER</div> -->
                  </div>
                  <div class="alert">
                    <div class="reject_texts" v-if="data.offerStatusLabel == 'Out of Stock'">{{ data.offerStatusLabel }}
                    </div>
                    <div v-else class="reject_text">{{ data.offerStatusLabel }}</div>

                    <span class="info" v-if="data.offerStatusLabel == 'Rejected'"> Your offer of {{ data.offerQuantity
                    }}
                      @ {{ data.offerPrice }} was not accepted.</span>
                  </div>
                  <hr class="hr">
                </div>
              </div>
              <div v-else></div>
            </div>
            <div class="card_bottom">
              <div class="card_select">
                <span class="items"> Items Selected for Checkout:</span>
                <div class="nums">{{ index.checkoutTotal }}</div>
              </div>
              <div class="qtyIndex">
                <span class="items">Qty:</span>
                <div class="numr">{{ index.quantity }}</div>
              </div>
              <div class="subtotal">
                <div>Subtotal for W04-BBY:</div>
                <div class="num">${{ index.subTotal }}</div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 card_btn" v-for="(k, value) in index.group">
              <div class="checkoutbtn" v-if="k.Status == 1">
                <!-- :class="[index.quantity ? '' : 'disabled', 'btn', 'btn-primary', 'bbtn-lgtn']" -->
                <a :class="['btn', 'btn-primary', 'bbtn-lgtn']" @click="convert">Checkout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p class="Nodata">No data present</p>
  </div>
  <!-- quick select 小弹窗 -->
  <div v-if="applySelectPop">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">Quick Select for Checkout</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>
        <div class="popup_middle">
          <div class="popup_first" v-for="(k, value) in quickSelect.group">
            <label class="d-block" :for="k.Name + '_' + quickSelect.warehouse">
              <input class="checkbox_animated" :id="k.Name + '_' + quickSelect.warehouse" type="checkbox"
                v-model="selectedOptions" :value="k" @change="calculatePriceAndQuantity">
              <span class="old_style">{{ k.Name }}<span>({{ k.itemCountFormatted }})</span>at current Stock List
                price</span>
            </label>
          </div>
          <div class="selector">
            <button class="btn_cel" @click="hidePopup">CLEAR ALL</button>
            <button class="btn_del" @click="applyCart()">APPLY ({{ itemCountFormatted }})</button>
          </div>
          <div class="message">{{ message }}</div>
        </div>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
  <!-- quicK delete小弹窗 -->
  <div v-if="deleteQuickPop">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">Quick Select for Delete</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>
        <div class="popup_middle">
          <div class="popup_first" v-for="(k, value) in quickSelect.group">
            <label class="d-block" :for="k.Name">
              <input class="checkbox_animated" :id="k.Name" type="checkbox" v-model="selectedOptions" :value="k"
                @change="calculatePriceAndQuantity">
              <span class="old_style">{{ k.Name }}<span>({{ k.itemCountFormatted }})</span></span>
            </label>

          </div>
          <div class="selector">
            <button class="btn_cel" @click="hidePopup"> CANCEL</button>
            <button class="btn_del" @click="deleteOffers()">DELETE({{ itemCountFormatted }})</button>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
  <!-- apply申请后的事项弹窗 -->
  <div v-if="applyPricingPop" v-for="(k, value) in index.group">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">Note on Pricing</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>

        <div class="popup_middle">
          <div class="popup_write">You are selecting Rejected or Expired offers for checkout.Please note that these
            items
            are now priced at the CURRENT STOCKLIST PRICE.</div>
          <div class="selector">
            <button class="btn_back" @click="back">BACK</button>
            <button class="btn_del" @click="nextStep(k.items[value])">CONTINUE</button>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
</template>
<script>
import { mapState, useStore, mapActions } from 'vuex';
import { axiosUpdateCart, axiosDelCart, axiosCheckout, getCartData } from "@/api/cart";
export default {
  created() {
    this.CartListData();
  },
  data() {
    return {
      cartList: [],
      deleteQuickPop: false,
      rejectPop: true,
      applySelectPop: false,
      selectedOptions: [],
      quickSelect: null,
      itemCountFormatted: 0,
      applyPricingPop: false,
      showUpdateOffer: false,
      message: '',
      data: null
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('cart', ['getAllCart']),
    CartListData() {
      getCartData().then((data) => {
        this.cartList = data;
        console.log(this.cartList)
      });
    },
    back() {
      this.$router.go(-1);
    },
    makeOffer(item) {
      this.offer = item; console.log(this.offer);
      this.showUpdateOffer = true;
    },
    quick_del(obj) {
      this.itemCountFormatted = 0;
      this.deleteQuickPop = true;
      this.quickSelect = obj;
    },
    selectPop(obj) {
      this.itemCountFormatted = 0;
      this.applySelectPop = true;
      this.quickSelect = obj;
    },
    hidePopup() {
      this.applyPricingPop = false;
      this.showUpdateOffer = false;
      this.deleteQuickPop = false;
      this.applySelectPop = false;
      this.itemCountFormatted = 0;
      this.selectedOptions = [];
    },
    updateOffer(data) {
      let item = [{ "id": data.itemNumber, "offerQuantity": data.offerQuantity, "isOffer": true, "offerPrice": data.offerPrice }]
      axiosUpdateCart(item).then(rep => {
        if (rep.status == false) {
          this.message = rep.message;
        } else {
          this.hidePopup();
          this.CartListData();
        }
      });
    },
    card_pop(k) {
      k.show = !k.show;
      this.message = ''
    },
    deleteOffer(item) {
      let items = [{ 'id': item.itemNumber }];
      axiosDelCart(items).then(rep => {
        this.CartListData();
      });
    },
    convert() {
      let items = [];
      // console.log(this.cartList);
      this.cartList.forEach(function (warehouse, index) {
        warehouse.group.forEach(function (getItems, itemsKey) {
          getItems.items.forEach(function (item, itemKey) {
            if (item.readyToCheckout) {
              items.push({ "id": item.itemNumber, "quantityRequested": null, "offerQuantity": item.offerQuantity, "isOffer": true, "offerPrice": item.offerPrice })
            }
          });
        })
      });
      if (items.length > 0) {
        axiosCheckout(items).then(rep => {
          this.CartListData();
        });
      }
    },
    //delete方法
    deleteOffers() {
      let items = [];
      this.selectedOptions.forEach(function (group, index) {
        group.items.forEach(function (item, itemKey) {
          items.push({ 'id': item.itemNumber });
        });

      });
      if (items.length > 0) {
        axiosDelCart(items).then(rep => {
          this.CartListData();
        });
      }
      this.hidePopup();
    },
    applyCart(data) {
      /*if(this.itemCountFormatted !=0){
       return this.applyPricingPop=true;
      }else{
        this.message='請選擇要添加的選項';
      }*/
    },
    //continue方法
    nextStep(data) {
      const item = [{ "id": data.itemNumber, "quantityRequested": null, "offerQuantity": data.offerQuantity, "isOffer": true, "offerPrice": data.offerPrice }];
      this.getAllCart(item);
    },
    calculatePriceAndQuantity() {
      // console.log(this.selectedOptions);
      this.quantity = this.selectedOptions.length; // 选中的数量等于选中选项数组的长度
      this.itemCountFormatted = this.selectedOptions.reduce((total, option) => total + option.itemCountFormatted, 0);
    },
    clickwarehouse(item) {
      // 获取目标元素的 DOM 节点
      const targetElement = this.$refs.warehoseTarget[item];
      // console.log(targetElement);
      if (targetElement) {
        targetElement.scrollIntoView();
      } else {
        console.log('Target Element not found.');
      }
    },
    scrollToTarget(value) {
      console.log(value);
      // 获取目标元素的 DOM 节点
      const targetElement = this.$refs.scrollTarget[value];
      // console.log(targetElement);
      // 使用 scrollIntoView 方法滑动到目标元素
      if (targetElement) {
        console.log('Target Element:', targetElement);
        targetElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.log('Target Element not found.');
      }
    }

  }
}
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 12px;
}

.Nodata {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
  height: 5vw;
  line-height: 6vw;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.card-title {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
}

.message {
  font-size: 14px;
  color: red;
  font-weight: 500;
}

.title {
  margin-bottom: 12px;
}

.qty input,
.price input {
  width: 50%;
}

.quickDelete {
  margin-top: -30px;
  color: #0070b9;
  cursor: pointer;
}

.icofont {
  cursor: pointer;
  font-size: 17px;
  color: #0070b9;
}

.itemtotal {
  cursor: pointer;
}

.cardgrops {
  padding: 0 30px;
}

.popup {
  position: fixed;
  top: 20%;
  left: 30%;
  width: 40%;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 101;
  margin: 30px auto;
}

.popup_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vw;
}

.popup_title {
  font-weight: 500;
  margin-bottom: 5px;
  width: 90%;
  font-size: 16px;
}

.old_style {
  font-size: 14px;
  color: #739199;
  cursor: pointer;
}

.popup_first {
  display: flex;
  margin-bottom: 3vw;
  justify-content: flex-start;
  align-items: center;
}

.popup_write {
  margin-bottom: 50px;
}

.popup-cover {
  background: #000000;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.btn_cel,
.btn_del {
  border: none;
  width: 130px;
  background-color: #0070b9;
  border-color: #0070b9 !important;
  color: #ffffff;
  border-radius: 15px;
  margin-left: 12px;
  padding: 0.5rem;

}

.btn_cel {
  background-color: #ffffff;
  border-color: #ffc107;
  color: #ffc107;
}

.btn_back {
  border: 0;
  color: blue;
  font-weight: 500;
  background-color: #ffffff;
}

.card_btn {
  padding: 30px 20px;
}

.cartdatas {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}

.items {
  font-weight: 500;
}

.text {
  color: #0070b9;
}

.tips {
  color: #3a494d;
  font-weight: 500;
}

.quanty {
  width: 45%;
  display: flex;
}

.warehouse {
  display: inline-table;
  font-size: 20px;
  color: #0070b9;
  font-weight: 1000;
  margin: 5px 20px 10px 0px;
}

.warehouse-area {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 0px 16px;
  margin-bottom: 30px;
}

.card-text {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.card-subtitle {
  position: absolute;
  right: 20px;
}

.items_text {
  text-align: center;
  font-weight: 500;
  color: #739199;
}

.icofont-ui-delete {
  cursor: pointer;
}

.rejected,
.makeOffer {
  cursor: pointer;
}

.reject {
  width: 150px;
  height: 25px;
  background-color: #739199;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  cursor: pointer;
}

.form-check-input {
  background-color: #739199;
  border: 1px solid #ffffff;
  box-shadow: none;
}

.dbqiuck_del {
  color: #0070b9;
  margin-bottom: 10px;
}

.items,
.subtotal {
  color: #739199;
}

.num {
  text-align: center;
  font-weight: 500;
  color: #000000;
}

.checkout,
.checkouts {
  display: flex;
  justify-content: space-between;
}

.checkoutbtn {
  display: flex;
  justify-content: flex-end;
}

.checkouts {
  margin-top: 95px;
}

.btn_checkout {
  width: 10vw;
  height: 10vh;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 10px;
}

.slected_text {
  margin-left: 25px;
  color: #7391a6;
}

.subtitles {
  margin-bottom: 20px;
  margin-top: 10px;
}

.datainfo {
  display: flex;
  margin-bottom: 20px;
}

.qty {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
}

.price {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
}

.description {
  width: 50%;
}

.outStock {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
}

.itemid {
  color: #605a53;
  margin-bottom: 10px;
}

.numinfo {
  width: 35%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  margin-bottom: 20px;
}

.alert {
  display: flex;
  padding: 0;
}

.reject_text,
.reject_texts {
  width: 105px;
  height: 25px;
  background-color: #739199;
  text-align: center;
  color: white;
  border-radius: 20px;
}

.reject_texts {
  background-color: red;
}

.info {
  margin-left: 15px;
}

.card_bottom {
  display: flex;
  justify-content: center;
}

.card_select {
  width: 45%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.nums {
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.numr {
  text-align: center;
  font-weight: 500;
}

.subtotal {
  margin-left: 10vw;
}

.hr {
  border: 1.5px solid #dce3e5;
}

.offer-status {
  display: inline-flex;
  margin-right: 6px;
}


.selector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.selector_all {
  display: flex;
}

.selector_button {
  text-align: center;
}

.selector_all input {
  width: 60px;
  height: 30px;
  margin: 0 10px;
}


.add_cart {
  color: white;
  background-color: #0070b9;
  border: 0;
  width: 180px;
  padding: 7px;
  border-radius: 17px;
  font-size: 13px;
  display: block;
}

.new_qty,
.new_price {
  line-height: 34px;
}

.old_price {
  margin-left: 5vw;
}

.query_btn {
  width: 50px;
    border: 1px solid #0070b9;
    color: #0070b9;
    margin-right: 90px;
    font-size: 13px;
    background-color: #ffffff;
    height: 2vw;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 4px;
}

.query_btn:hover {
  background-color: #0070b9;
  color: #ffffff;
}

.delete_querybtn {
  display: flex;
  align-items: center;
}

.dataErroData {
  color: red;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
}

.avails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media(max-width:850px) {
  .cartlist {
    min-width: 100%;
  }

  .card {
    min-width: 100%;
  }

  .popup {
    left: 5%;
    width: 90%;
    margin: auto;
  }

  .quanty {
    display: flex;
    width: 6Z0%;
    margin-right: 26px;
  }
  .description{
    width: 55%;
    display: flex;
    align-items: center;
  }

  .qty {
    width: 55%;
    display: flex;
    align-items: center !important;
    flex-direction: column;
    justify-content: space-between !important;
    margin-top: 20px;
    font-weight: 500;
  }

  .price {
    width: 65% !important;
    display: flex;
    align-items: center !important;
    flex-direction: column;
    justify-content: space-between !important;
    font-weight: 500;
    margin-top: 20px;

  }

  .qty input,
  .price input {
    width: 49%;
  }

  .query_btn {
    width: 73px;
    border: 1px solid #0070b9;
    color: #0070b9;
    margin-right: 52px;
    font-size: 10px;
    background-color: #ffffff;
    height: 3vw;
    cursor: pointer;
    margin-top: 48px;
    border-radius: 4px;
  }
}

.col-md-offset-9 {
  margin-left: 85%;
}

@media (max-width:700px) {
  .quickDelete {
    margin-top: 0px !important;
  }

  .card_btn {
    padding: 3vw;
  }

  .numinfo {
    width: 40%;
  }

  .add_cart {
    width: 130px;
  }

  .datainfo {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .description {
    width: 100%;
  }

  .quanty {
    display: flex;
    flex-direction: column;
  }

  .qty {
    width: 100%;
    display: flex;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-top: 20px;
    font-weight: 500;
  }

  .price {
    width: 100% !important;
    display: flex;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    font-weight: 500;
    margin-top: 20px;

  }

  .qty input,
  .price input {
    width: 47%;
  }

  .query_btn {
    width: 55px;
    border: 1px solid #0070b9;
    color: #0070b9;
    font-size: 10px;
    background-color: #ffffff;
    height: 5vw;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .delete_querybtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

@media (max-width:540px) {
  .cartlist {
    min-width: 100%;
  }

  .card-title {
    font-size: 20px;
  }

  .card {
    min-width: 80%;
  }

  .btn_up,
  .text-warning {
    font-size: 10px;
  }

  .quickDelete {
    margin-top: 5px !important;
    font-size: 18px;
  }

  .items_text {
    margin-top: 10vw;
  }

  .card_bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .subtotal {
    margin-left: 0vw;
    display: flex;
    justify-content: space-between;
    margin-left: 6vw;
    width: 86%;
    padding-top: 15px;
  }

  .card_select {
    display: flex;
    justify-content: space-between;
    width: 86%;
    flex-direction: row;
    margin-left: 6vw;
    padding-top: 15px;

  }

  .qtyIndex {
    display: flex;
    justify-content: space-between;
    width: 86%;
    flex-direction: row;
    margin-left: 6vw;
    padding-top: 15px;
  }

  .avail {
    width: 38%;
  }

  .prices {
    width: 40%;
  }

  .cartdatas {
    font-size: 13px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .add_cart {
    padding: 5px;
    font-size: 10px;
  }

  .reject {
    width: 64vw;
  }


  .card_btn {
    width: 70vw;
    margin-left: 9px;
  }

  .checkoutItems {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }

  .checkoutbtn a {
    width: 100%;
  }

  .numinfo {
    width: 60%;
  }

  .datainfo {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .description {
    width: 100%;
  }

  .quanty {
    display: flex;
    flex-direction: column;
    width: 85%;
  }

  .qty {
    width: 100%;
    display: flex;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-top: 20px;
    font-weight: 500;
  }

  .price {
    width: 100% !important;
    display: flex;
    align-items: center !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    font-weight: 500;
    margin-top: 20px;

  }

  .qty input,
  .price input {
    width: 47%;
  }

  .query_btn {
    width: 55px;
    border: 1px solid #0070b9;
    color: #0070b9;
    font-size: 10px;
    background-color: #ffffff;
    height: 7vw;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .delete_querybtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>