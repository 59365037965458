<template>
  <div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="row ">
        <div class="col-12">
          <div class="login-card">
            <div class="login-main">
              <div class="theme-form login-form">
                <h4 class="mb-3">Reset Your Password</h4>
                <div class="form-group">
                  <label>Enter Your Email</label>
                  <div class="row">
                    <div class="col-8 col-sm-12">
                      <input class="form-control" type="email" :disabled="verificationSuccess" v-model="Email" placeholder="xxx@xxx.com" required>
                    </div>
                  </div>
                </div>
                <div class="message-area">
                  <div v-if="error" class="error">{{ error }}</div>
                  <div v-if="verificationSuccess" class="success">We received a request to reset your password. You will receive an email that provides further instructions on resetting your password</div>
                </div>
                <div class="form-group" v-if="!verificationSuccess">
                  <button class="btn btn-primary btn-block" type="submit" @click="verifyEmail">Send</button>
                </div>
                <p>Already have an password?<a class="ms-2" href="/auth/login">Sign in</a></p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosResetPasswordProfile } from "@/api/userprofile"
export default {
  name: 'forget_password',
  data() {
    return {
      type: 'password',
      Email: '',
      error: '',
      verificationSuccess: false,
      submitted: false
    }
  },
  methods: {
    verifyEmail(){
      axiosResetPasswordProfile({email:this.Email}).then((result) => {
          if(result.status) {
            this.verificationSuccess = true;
            this.error = '';
          } else {
            this.verificationSuccess = false;
            this.error = result.message;
          }
        });
    }
  }
}
</script>
<style scoped>
.error {
  margin: 10px;
  color: red;
}

.success {
  color: green;
}

.message-area {
  padding:10px;
}
</style>