<template>
  <div>
    <div>
      <div class="header">
        <h3>Orders #{{$route.params.id}}</h3>
      </div>
      <div class="container-fluid">
       <div class="card" v-show="order">
          <div class="card-body row">
            <div class="col-sm-12 right" v-if="order.Status == 'Hold'">
              <button class="btn btn-primary preference-button" @click="checkout">Checkout</button>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="col">
                <div class="mb-3 row">
                  <label class="col-sm-2 col-form-label">Order Date:</label>
                  <div class="col-sm-3">
                    <label class="col-form-label label-cont">{{ order.OrderDate }}</label>
                  </div>
                  <label class="col-sm-2 col-form-label">Order Number:</label>
                  <div class="col-sm-3">
                    <label class="col-form-label label-cont">{{ order.OrderNumber }}</label>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-sm-2 col-form-label">Warehouse:</label>
                  <div class="col-sm-3">
                    <label class="col-form-label label-cont">{{ order.warehouse }}</label>
                  </div>
                  <label class="col-sm-2 col-form-label">Order Status:</label>
                  <div class="col-sm-3">
                     <label class="col-form-label label-cont">{{ order.Status }} </label>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-sm-2 col-form-label">Shipping Address:</label>
                  <div class="col-sm-3">
                    <label class="col-form-label label-cont">{{ order.ShippingAddress }}</label>
                  </div>
                  <label class="col-sm-2 col-form-label">Billing Address:</label>
                  <div class="col-sm-3">
                    <label class="col-form-label label-cont">{{ order.BillingAddress }}</label>
                  </div>
                </div>
                <!--<div class="mb-3 row">
                  <label class="col-sm-2 col-form-label">Shipping Method:</label>
                  <div class="col-sm-3">
                    <label class="col-form-label label-cont">{{ order.ShippingMethod }}</label>
                  </div>
                  <label class="col-sm-2 col-form-label">Payment Method:</label>
                  <div class="col-sm-3">
                    <label class="col-form-label label-cont">{{ order.PaymentMethod }}</label>
                  </div>
                </div>-->
              </div>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="mb-3 row">
                <label class="col-sm-2 col-form-label">Items:</label>
               
              </div>
              <div class="table-responsive" style="padding:10px;">
              <table class="table">
                  <thead class="table-success">
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Ordered Qty</th>
                      <th scope="col">Canceled Qty</th>
                      <th scope="col">Backorder Qty</th>
                      <th scope="col">Shipped Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(detail, index) in order.detail" :key="index">
                      <td>{{ detail.Name }}</td>
                      <td>{{ detail.Quantity }}</td>
                      <td>{{ detail.CanceledQuantity }}</td>
                      <td>{{ detail.BackorderQuantity }}</td>
                      <td>{{ detail.ShippedQuantity }}</td>
                      <td>{{ detail.Price }}</td>
                      <td>{{ detail.Amount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosGetOrderDetail,axiosCheckout } from "@/api/order"
export default {
  created() {
    this.syncData();
  },
  data() {
    return {
      id: null,
      order:false,
    };
  },
  computed: {
    
  },
  methods: {
    syncData() {
      this.id = this.$route.params.id;
      axiosGetOrderDetail(this.id).then((result) => {
        this.order = result;
      });
    },
    checkout() {
      axiosCheckout(this.id).then((result) => {
        this.syncData();
      });
    }
  },
}
</script>
<style scoped>
.right {
  text-align: right;
}

.header {
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top:12px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

@media(max-width:850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
}

.label-cont {
  font-weight: normal !important;  
}

.job-pagination nav ul {
  justify-content: right;
}
@media(max-width:540px) {

  #basic-9_filter input{
    margin-bottom: 5vw;
  }
  .table{
    width:50vw;
  }
}
</style>