<template>
  <div class="sidebar-user text-center">
    <router-link to="/users/edit">
      <h6 class="mt-3 f-14 f-w-600">{{ BusinessReferencesCompanyName }}</h6>
    </router-link>
    <p class="mb-0 font-roboto">{{ fullName }}</p>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      userData:{
        fullName:'',
      BusinessReferencesCompanyName:''
      }
      
    };
  },
  created() {
    
    // 从本地存储中获取数据并赋值给组件的 data 属性
    const userlocalData = localStorage.getItem('user');
     this.userData=JSON.parse(userlocalData);
    const OwnershipFirstName=this.userData.OwnershipFirstName;
    const OwnershipLastName = this.userData.OwnershipLastName;
    this.fullName = `${OwnershipFirstName}  ${OwnershipLastName}`;
    this.BusinessReferencesCompanyName = this.userData.BusinessReferencesCompanyName;
  },
  computed: {},
  methods: {
    theFormat(number) {
      return number.toFixed(1);
    },
  },
};
</script>
<style scoped>
@media(max-width:900px) {
  .font-roboto{
    margin-left: 15%;
  }
}
@media(max-width:700px) {
  .font-roboto{
    margin-left: 14%;
  }
}
@media(max-width:480px) {
  .font-roboto{
    margin-left: 38px;
  }
}
</style>


