import request from "./request"
 
export const get = (url, params, headers) => request({ method: "get", url, params, headers });
 
export const post = (url, data, headers) => request({ method: "post", url, data, headers });

export const put = (url, data, headers) => request({ method: "put", url, data, headers });
 
export const patch = (url, data, headers) => request({ method: "patch", url, data, headers })
 
export const del = (url, data, headers) => request({ method: "delete", url, data, headers })
