<template>
  <div>
    <div>
      <div class="header">
        <h3>Shipping and Billing Preferences</h3>
      </div>
      <div class="container-fluid">
       <div class="card">
          <div class="card-body row">
            <div class="col-sm-12 col-lg-12 col-xl-12">
                <label class="col-form-label">
                  This information will be used as defaults during checkout and to fullfill orders created from awarded auction lots. For Stock List orders you will have the option of changing your selections during checkout.
                </label>
             </div> 
             <div class="col-sm-12 col-lg-12 col-xl-12 " @click="Goback()">
                <div class="col">
                  <div class="mb-6 row">
                    <div class="col-sm-1">
                      <i class="icofont icofont-rounded-left"></i>
                    </div>
                    <div class="col-sm-5 back">
                      BACK
                    </div>
                     
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="col shippingSelected">
                  <div class="mb-6 row">
                      <label class="col-sm-4 col-form-label title">* Shipping Address</label>
                      <div class="col-sm-12">
                      </div>
                      <label class="col-sm-12 hint">Please select a shipping address</label>
                      <div class="col-sm-12 inputText" v-if="shippingData">
                        <input type="text"  v-model="shippingData.ShippingAddress" disabled/>
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="col shippingSelected">
                  <div class="mb-6 row">
                      <label class="col-sm-4 col-form-label title">* Shipping Method</label>
                      <div class="col-sm-12">
                      </div>
                      <label class="col-sm-12">How should we ship your order?</label>
                      <label class="col-sm-12 " >
                        <input type="radio" checked />Speed: Bill My Account (Use your own UPS, FEDEX, or DHLÂ Account)
                      </label>
                  </div>
                </div>
              </div>
             
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="col shippingSelected">
                  <div class="mb-6 row" v-if="shippingData">
                    <label for="shipping">* Your shipping account details</label>
                    <select  id="shipping" v-model="shippingData.Carrier">
                        <option value="">Choose a Carrier</option>
                        <option value="UPS" :checked="shippingData.Carrier === 'UPS'">UPS</option>
                        <option value="FEDEX" :checked="shippingData.Carrier === 'FEDEX'">FEDEX</option>
                        <option value="DHLÂ" :checked="shippingData.Carrier === 'DHLÂ'">DHLÂ</option>

                    </select>
                  </div>
                  <div class="col-sm-12 inputText" v-if="shippingData">
                    <input type="text" v-model="shippingData.ServiceLevel"/>
                  </div>
                  <div class="col-sm-12 inputText" v-if="shippingData">
                    <input type="text" v-model="shippingData.AccountNumber"/>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12 inputText">
                <div class="col shippingSelected">
                  <div class="mb-6 row">
                      <label class="col-sm-12 col-form-label">Shipping Instructions (optional)</label>
                      <div class="col-sm-12" v-if="shippingData">
                        <input type="text" v-model="shippingData.ShippingInstructions"/>
                      </div>
                  </div>
                </div>
              </div >
              <div class="sub_ok">
                <button  type="submit" @click="submitShipping">OK</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {axiosShipping,axiosEditShipping } from "@/api/Shipping"
export default {
  created() {
    this.syncData();
  },
  data() {
    return{
      shippingEdit:null,
      shippingData:{
        ShippingAddress:'',
        Carrier:'',
        ServiceLevel:'',
        AccountNumber:'',
        ShippingInstructions:''
      },
      originalShippingData:{}
    }
  },
  methods: {
    Goback(){
      this.$router.go(-1);
    },
    syncData() {
      axiosShipping().then((result) => {
        this.shippingData = result.data;
      });
      
    },
    submitShipping(){
      let itemdata = { "ShippingAddress":this.shippingData.ShippingAddress , "Carrier": this.shippingData.Carrier, "ServiceLevel": this.shippingData.ServiceLevel,"AccountNumber":this.shippingData.AccountNumber ,"ShippingInstructions":this.shippingData.ShippingInstructions};
      axiosEditShipping(itemdata).then((result) => {
        // console.log('是否能请求成功');
        if(result.status==true){
          // console.log(this.shippingEdit);
          this.$router.push('/preference/review');
        }
       
      });
    }
  }
  
}
</script>
<style scoped>
.header {
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top:12px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.header-note {
  font-size: 17px;
  line-height: 25px;
}

.address-area {
  margin-top: 50px;
}
.EditShipping{
  color: #0070b9;
  font-size: 16px;
  font-weight: 600;
  margin-left: 12px;
}
i{
  font-size:18px;
  font-weight:700;
  line-height: 1.5;
  color:#0070b9;
}
.back{
  font-size:18px;
  font-weight:500;
  margin-left: -4%;
  color:#0070b9;
}
.title{
  font-size:18px !important;
  font-weight:700 !important;
}
.hint{
  font-size:14px !important;
}
.inputText{
  position: relative;
  display: inline-block;
}
.inputText input{
  width: 90%;
  height: 3vw;
  font-size: 15px;
  font-weight: 500;
  margin-top:14px;
  padding: 15px;
  border: 1px solid #ccc;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.inputText input:focus{
  border-color: #3a3b3b;
    box-shadow: 0 0 8px rgba(54, 54, 55, 0.6);
    outline: none;
}
.shippingSelected{
  margin-top:22px;
}
#shipping{
  width: 86.5%;
  height: 4vw;
  font-size: 18px;
  margin-left: 12px;
  font-weight: 500;
  padding: 10px;
  border: 1px solid #ccc;
  transition: border-color 0.3s, box-shadow 0.3s;
}
#shipping:focus{
  border-color: #313131;
  box-shadow: 0 0 5px rgba(56, 56, 56, 0.6);
  outline: none;
}
.sub_ok{
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.sub_ok button{
  width: 14%;
  font-weight: 500;
  height: 3vw;
  font-size: 16px;
  border: 0;
  background-color: #0070b9;
  color: white;
  border-radius: 6px;
}
@media(max-width:850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
  .inputText input{
    height:8vw;
  }
  #shipping{
    height:6vw;
  }
}

</style>