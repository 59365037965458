<template>
  <div class="col-xl-3 xl-30 box-col-4">
    <div class=" job-sidebar">
      <a class="btn btn-primary job-toggle" href="javascript:void(0)" @click="collapseFilter()">job filter</a>
      <div class="job-left-aside custom-scrollbar" :class="filtered ? 'open' : ''">
        <div class="default-according style-1 faq-accordion job-accordion" id="accordionoc" v-if="jobsFilter">
          <div class="row">
            <div class="col-xl-12 ">
              <div class="card" v-for="(job, item) in jobsFilter.groups">
                <div class="card-header">
                  <h5 class="mb-0 p-0">
                    <button class="btn btn-link ps-0" data-bs-toggle="collapse"  :data-bs-target="'#collapseicon_' + job.value" 
                      :aria-expanded="filter[job.value] ? 'true' : 'false'" aria-controls="collapseicon"
                      :class="[filter[job.value] ? 'active' : '', 'collapsible']" v-on:click="toggle(job.value)">{{
                        job.displayValue }}</button>
                  </h5>
                </div>
                <div :class="[filter[job.value] ? 'block' : 'none', 'content']" >
                  <div class="card-body filter-cards-view animate-chk">
                    <div class="checkbox-animated" v-for="(k, index) in job.items" :key="k.id" v-show="filter[job.value] || k.selected == true  ? true : false">
                      <label class="d-block" :for="'checkbox_' + job.value + '_' + k.id">
                        <input class="checkbox_animated" :id="'checkbox_' + job.value + '_' + k.id" type="checkbox" :value="k.value"
                          @click="linkJump(job.value, k.value, $event)">
                        {{ k.displayValue }}
                        <span class="bracket">({{ k.countFormatted }})</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="checkbox-animated checkbox-animated-ostock">
                <label :for="includOutStock" class="d-block"><input type="checkbox" class="checkbox_animated" :id="includOutStock" v-model="includOutStock  " @change="includOutStockOptions()" />
                  Include Out of Stock
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState({
      jobsFilter: state => {
        return state.jobs.jobsFilter;
      },
      jobsSelected: state => state.jobs.selectedFilters,
    })
  },
  data() {
    return {
      params: {
        page: 1,//当前页
        pagesize: 10,//每一页需要显示的条数
        name: '',
        includOutStock: null,
        selectedFilters: null,
      },
      filtered: false,
      filter: {
          'Category':false,
          'Warehouse':false,
          'LockStatus':false,
          'Grade':false,
          'Manufacturer':false,
          'Model':false
      },
      includOutStock: false
    };
  },
  methods: {
    ...mapActions('jobs', ['getAllJob']),
    collapseFilter() {
      this.filtered = !this.filtered;
    },
    toggle(category) {
      this.filter[category] = !this.filter[category];
    },
    linkJump(group, value, event) {
      event.stopPropagation();
      if (event.target.checked) {
        //点击某个input 把对应的值保存到数组
        if (this.jobsSelected[group.toLowerCase()]) {
          this.jobsSelected[group.toLowerCase()].push(value);
        }
      } else {
        if (this.jobsSelected[group.toLowerCase()]) {
          var arrIndex = this.jobsSelected[group.toLowerCase()].indexOf(value);
          this.jobsSelected[group.toLowerCase()].splice(arrIndex, 1);
        }
      }
      console.log(this.jobsSelected);
      this.params.page = 1;
      this.params.selectedFilters = this.jobsSelected;
      this.getAllJob(this.params);
    },
    includOutStockOptions() {
      this.params.includOutStock = this.includOutStock;
      this.params.page = 1;
      this.getAllJob(this.params);
    }
  }
}


</script>
<style>
.checked {
  text-decoration: line-through;
  color: gray;
}

.material-icons-container {
  display: inline-flex;
  align-items: center;
}

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox-animated-ostock{
  padding:0 22px;
}

.checkbox-animated-ostock{
  padding:0 22px;
}

.filter-cards-view .checkbox-animated {
    margin-top: 15px !important;
    margin-bottom: 20px !important;
}

.default-according .card-body {
  padding:0 22px !important;
}


.card {
  margin-bottom: 15px !important;
}

</style>
