import { createRouter, createWebHistory } from 'vue-router'
import BodyView from '../layout/BodyView.vue'
import product_list from "../components/product/product-list/product_list"
import order_list from "../components/order/order_list"
import order_detail from "../components/order/order_detail"
import cart_list from "../components/cart/cart/cart_list.vue"
import alert_list from "../components/alert/alert/alert_list.vue"
import gradingscale from "../components/gradingscale/index.vue"
import notification from "../components/notification/index.vue"
import account from "../components/account/index.vue"
import preference from "../components/account/preference.vue"
import preferenceShipping from "../components/account/preferenceShipping.vue"
import preferencePayMethod from "../components/account/preferencePayMethod.vue"
import firebase from 'firebase';
import auth from "../auth/auth.vue"
import callback from '../components/callback';
import userProfile from "../components/users/profile/userProfile"
import editProfile from "../components/users/editProfile/editProfile"
import errorPage1 from '../errors/errorPage1.vue';
import errorPage2 from '../errors/errorPage2.vue';
import errorPage3 from '../errors/errorPage3.vue';
import errorPage4 from '../errors/errorPage4.vue';
import NotFoundComponent  from '../components/NotFound/NotFoundComponent.vue';
import forget_password from "../components/authentication/forget_password"
import reset_password from "../components/authentication/reset_password"
import login from '../auth/login';
import register from '../components/authentication/ragister_simple.vue';
import SuccessPage from '../components/authentication/SuccessPage.vue';

const routes = [
  {
    path: '/',
    component: BodyView,
    children: [
      {
        path: '',
        name: '',
        component: product_list,
        meta: {
          auth: true 
        }
      }
    ]
  },
  {
    path: '/orders',
    component: BodyView,
    children: [
      {
        path: '',
        name: 'orders',
        component: order_list,
        meta: {
          auth: true 
        }
      },
      {
        path: ':id',
        name: 'orderdetail',
        component: order_detail,
        meta: {
          auth: true 
        }
      }
    ]
  },  
  {
    path: '/error-page1',
    name: 'errorPage1',
    component: errorPage1,

  },
  {
    path: '/',
    component: BodyView,
    children: [
      {
        path: '/cart_list',
        name: 'cartslist',
        component: cart_list,
        meta: {
          auth: true 
        }
      }
    ]

  },
  {
    path: '/',
    component: BodyView,
    children: [
      {
        path: 'alert_list',
        name: 'alert_list',
        component: alert_list,
        meta: {
          auth: true 
        }
      }
    ]

  },
  {
    path: '/error-page2',
    name: 'errorPage2',
    component: errorPage2,

  },
  {
    path: '/error-page3',
    name: 'errorPage3',
    component: errorPage3,

  },
  {
    path: '/error-page4',
    name: 'errorPage4',
    component: errorPage4,

  },
  {
    path: "/users",
    component: BodyView,
    children: [
      {
        path: "/users/profile",
        name: "userProfile",
        component: userProfile
      },
      {
        path: "/users/edit",
        name: "edit",
        component: editProfile
      },
    ]

  },
  {
    path: "/grading-scale",
    component: BodyView,
    children: [
      {
        path: "",
        name: "gradingscale",
        component: gradingscale
      },
    ]
  },
  {
    path: "/notification",
    component: BodyView,
    children: [
      {
        path: "",
        name: "notification",
        component: notification
      },
    ]
  },
  {
    path: "/account",
    component: BodyView,
    children: [
      {
        path: "",
        name: "account",
        component: account
      },
    ]
  },
  {
    path: "/preference",
    component: BodyView,
    children: [
      {
        path: "review",
        name: "preference",
        component: preference
      },
    ]
  },
  {
    path: "/account",
    component: BodyView,
    children: [
      {
        path: "preferenceShipping",
        name: "preferenceShipping",
        component: preferenceShipping
      },
    ]
  },
  {
    path: "/account",
    component: BodyView,
    children: [
      {
        path: "preferencePayMethod",
        name: "preferencePayMethod",
        component: preferencePayMethod
      },
    ]
  },
  {
    path: "/auth",
    component: auth,
    children: [
      {
        path: "login",
        name: "login",
        component: login
      }
    ]
  },
  {
    path: '/callback',
    name: 'callback',
    component: callback,
    meta: {
      title: 'Auth0',
    }
  },
  {
    path: "/authentication/forgetpassword",
    name: "forget-password",
    component: forget_password
  },
  {
    path: "/authentication/resetpassword",
    name: "reset-password",
    component: reset_password
  },
  {
    path: "/authentication/ragister",
    name: "register",
    component: register
  },
  {
    path: "/authentication/SuccessPage",
    name: "SuccessPage",
    component: SuccessPage
  },
  { path: '/:catchAll(.*)', component: NotFoundComponent } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    // 如果有savedPosition，表示浏览器的前进后退操作
    if (savedPosition) {
      return savedPosition;
    } else {
      // 没有savedPosition，则滚动到顶部
      return { x: 0, y: 0 };
    }
  }
})

const isAuthenticated = () => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve(!!user); // Resolve with true if the user is authenticated, false otherwise
    });
  });
};

router.beforeEach(async(to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('user');  // 获取用户登录状态的方法，比如从 Vuex 中获取
  // console.log(isLoggedIn);
  // 如果目标路由需要身份验证
  if (to.meta.auth) {
    // 如果已经登录，则允许访问
    if (isLoggedIn) {
      next();
    } else {
      // 如果未登录，则重定向到登录页
      next({ name: 'login' });
    }
  } else {
    next();
  }
});

export default router
