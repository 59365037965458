
const state = {
	submitData:[],
}
const actions = {
	getAllSubmit: async ({commit,state} ,item) => {

		fetch('http://dev.api.stellar.com/api/v1/products/submit-offers', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(item)
		  })
			.then(response => response.json())
			.then(
				data =>{
					console.log(data),
					commit('getSubmitData',data);
				})
			.catch(error => console.error(error));
	}
}


const mutations = {
	getSubmitData: (state, item) => {
		state.submitData = item;
	},
	

}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}