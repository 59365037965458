<template>
  <div class="container">
    <div class="theme-form register-form">
      <div class="title-header">
        <h4>New Reseller Application</h4>
        <span class="title-line"></span>
      </div>
      <div class="first title">
        <h5>Business Contact Information</h5>
        <!-- CompanyLegalName -->
        <div class="mb-3">
          <label for="formCompanyLegalNameInput" class="form-label">Company Legal Name*</label>
          <input type="text" v-model="formData.CompanyLegalName" class="form-control" id="formCompanyLegalNameInput">
          <div class="Prompt" v-if="!formData.CompanyLegalName && showCountryPrompt">Company Legal Name is required</div>
        </div>
        <!-- FullLegalNameCompany -->
        <div class="mb-3">
          <label for="formCompanyRegistrationInput" class="form-label">EIN/BR/Company Registration*</label>
          <input type="text" v-model="formData.CompanyRegistration" class="form-control"
            id="formCompanyRegistrationInput">
          <div class="Prompt" v-show="!formData.CompanyRegistration && showCountryPrompt">EIN/BR/Company Registration is
            required</div>
        </div>
        <!-- RegisteredCompanyAddress -->
        <div class="mb-3">
          <label for="formRegisteredCompanyAddressInput" class="form-label">Registered Company Address(Shipping
            Address)*</label>
          <input type="text" v-model="formData.RegisteredCompanyAddress" class="form-control font"
            id="formRegisteredCompanyAddressInput">
          <span class="tips">Please complete this field only if you have a registered fictitious/trade name and attach
            proof</span>
          <div class="Prompt" v-show="!formData.RegisteredCompanyAddress && showCountryPrompt">Registered Company Address
            is required</div>
        </div>
        <div class="ownership_three">
          <!-- BusinessContactCity -->
          <div class="city">
            <input type="text" v-model="formData.BusinessContactCity" class="form-control"
              id="formBusinessContactCityInput" />
            <span class="hint">City</span>
          </div>
          <!-- BusinessContactProvince -->
          <div class="Province">
            <input type="text" v-model="formData.BusinessContactProvince" class="form-control"
              id="formBusinessContactProvinceInput" />
            <span class="hint">State/Province</span>
          </div>
          <!-- BusinessContactPostal -->
          <div class="ZIP">
            <input type="text" v-model="formData.BusinessContactPostal" class="form-control"
              id="formBusinessContactPostalInput" />
            <span class="hint"> ZIP/Postal</span>
          </div>
          <!-- BusinessContactCountry -->
          <div class="Country">
            <select v-model="formData.BusinessContactCountry" class="form-select">
              <option v-for="(index, value) in countryList" :key="index.id">{{ index.Name }}</option>
            </select>
            <span class="hint">Country</span>
          </div>
        </div>
        <!-- profile -->
        <div class="profile">
          <label class="form-label">Profile</label>
          <input class="form-control upload-profile" type="file" @change="updateFilePath">
          <div class="profile-area">
            <div v-for="(index, key) in Profile">
              <div v-if="index.name">
                <div>
                  {{ index.name }} <i class="icofont icofont-ui-delete" @click="removeFile(key)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mailing">
          <label for="formGroupExampleInput" class="form-label">Is the mailing address same to the Registered Company
            address?*</label>
          <div class="form-group">
            <div class="radio1 radio-primary radio-first">
              <input id="radioinline_Registered" type="radio" name="radioinline_Registered"
                @click="MailSameAsRegistered(1)" checked>
              <label class="mb-1" for="radioinline_Registered">YES</label>
            </div>
            <div class="radio1 radio-primary">
              <input id="radioinline2" type="radio" name="radioinline_Registered" @click="MailSameAsRegistered(0)">
              <label class="mb-1" for="radioinline2">NO</label>
            </div>
          </div>
        </div>
        <div v-if="formData.MailingSameAsRegistered == 0" class="mailing_yes_open">
          <!-- BusinessMailingAddress -->
          <div class="mb-3">
            <label for="formBusinessMailingAddressInput" class="form-label">Business Mailing Address</label>
            <input type="text" v-model="formData.BusinessMailingAddress" class="form-control"
              id="formBusinessMailingAddressInput">
            <div class="Prompt" v-if="!formData.BusinessMailingAddress && showCountryPrompt">Business Mailing Address is
              required</div>
          </div>
          <div class="ownership_three">
            <!-- BusinessMailingCity -->
            <div class="city">
              <input type="text" v-model="formData.BusinessMailingCity" class="form-control"
                id="formBusinessMailingCityInput" />
              <span class="hint">City</span>
            </div>
            <!-- BusinessMailingProvince -->
            <div class="Province">
              <input type="text" v-model="formData.BusinessMailingProvince" class="form-control"
                id="formBusinessMailingProvinceInput" />
              <span class="hint">State/Province</span>
            </div>
            <!-- BusinessMailingPostal -->
            <div class="ZIP">
              <input type="text" v-model="formData.BusinessMailingPostal" class="form-control"
                id="formBusinessMailingPostalInput" />
              <span class="hint"> ZIP/Postal</span>
            </div>
            <!-- BusinessMailingCountry -->
            <div class="Country">
              <select v-model="formData.BusinessMailingCountry" class="form-select">
                <option v-for="(index, value) in countryList" :key="index.id">{{ index.Name }}</option>
              </select>
              <span class="hint">Country</span>
            </div>
          </div>
        </div>
        <div class="first_1">

          <div class="ownershipn_whole">
            <!-- BusinessPhoneNumber -->
            <div class="first_fax">
              <label>Phone Number*</label>
              <input type="text" v-model="formData.BusinessPhoneNumber" class="form-control inputs"
                id="formBusinessPhoneNumberInput" />
              <span class="hint">Please include country code and city code</span>
              <div class="Prompt" v-if="!formData.BusinessPhoneNumber && showCountryPrompt">Phone Number is required</div>
            </div>
            <!-- BusinessFaxNumber -->
            <div class="first_fax">
              <label>Fax Number</label>
              <input type="text" v-model="formData.BusinessFaxNumber" class="form-control inputs"
                id="formBusinessFaxNumberInput" />
              <span class="hint">Please include country code and city code</span>
            </div>
          </div>
        </div>
      </div>
      <div class="three title">
        <h5>Accounts Payable Contact Information</h5>
        <div class="first_column">
          <div>
            <label for="formGroupExampleInput" class="form-name">Name*</label>
            <div class="column_top">
              <!-- PayableFirstName -->
              <div>
                <input type="text" v-model="formData.PayableFirstName" class="form-control"
                  id="formPayableFirstNameInput" />
                <span>First Name</span>
                <div class="Prompt" v-if="!formData.PayableFirstName && showCountryPrompt">First Name is required</div>
              </div>
              <!-- PayableLastName -->
              <div>
                <input type="text" v-model="formData.PayableLastName" class="form-control"
                  id="formPayableLastNameInput" />
                <span>Last Name</span>
                <div class="Prompt" v-if="!formData.PayableLastName && showCountryPrompt">Last Name is required</div>
              </div>
            </div>
          </div>
          <!-- PayablePhoneNumber -->
          <div class="first_name">
            <label>Phone Number*</label>
            <input type="text" v-model="formData.PayablePhoneNumber" class="form-control inputs"
              id="formPayablePhoneNumberInput" />
            <span class="hint">Please include country code and city code</span>
            <div class="Prompt" v-if="!formData.PayablePhoneNumber && showCountryPrompt">Phone Number is required</div>
          </div>
          <!-- PayableEmail -->
          <div class="first_name">
            <label>PayableEmail</label>
            <input type="text" v-model="formData.PayableEmail" class="form-control inputs" id="formPayableEmailInput" />
          </div>
        </div>
        <div class="second_column">
          <h3>Payment Policy</h3>
          <p>It is our Policy to comply with anti-money laundering obligations enacted by the federal government,
            including applicable provisions of the Money Laundering Control Act of 1986, The USA PATRIOT ACT of 2001, the
            rules and regulations overseen by the United States Treasury Department’s Office of Foreign Assets Control and
            Financial Crimes Enforcement Network as well as other legal requirements. We only accepts payments from the
            purchasing party. We will not accept third party payments from an entity that has not been listed below and
            that has not been approved by our security department.
            Wire transfers that do have the reseller's name and address and bank printed on the receipt will not be
            accepted.</p>
        </div>

        <div class="four_column">
          <!-- PayableNameofBank -->
          <div class="bankstyle">
            <label>Name of bank used for payment*</label>
            <input type="text" v-model="formData.PayableNameofBank" class="form-control"
              id="formPayableNameofBankInput" />
            <div class="Prompt" v-if="!formData.PayableNameofBank && showCountryPrompt">Name of bank used for payment is
              required</div>
          </div>
          <!-- PayableBankSWIFT -->
          <div class="bankstyle">
            <label>Bank SWIFT code or identifier*</label>
            <input type="text" v-model="formData.PayableBankSWIFT" class="form-control" id="formPayableBankSWIFTInput" />
            <div class="Prompt" v-if="!formData.PayableBankSWIFT && showCountryPrompt">Bank SWIFT code or identifier is
              required</div>
          </div>
          <!-- PayableBankTel -->
          <div class="PayableBankTel">
            <label>Bank's telephone number</label>
            <input type="text" v-model="formData.PayableBankTel" class="form-control" id="formPayableBankTelInput" />
          </div>
        </div>

        <!-- Email -->
        <div class="readyEmail">
          <label>Email*</label>
          <input type="text" v-model="formData.Email" class="form-control" id="Email" />
          <span class="hint">Email for login</span>
          <div class="Prompt" v-if="!formData.Email && showCountryPrompt">Email is required</div>
        </div>
      </div>

      <div class="ragister_button">
        <div class="Prompt">{{ error }}</div>
        <button class="btn-primary btn_submit" type="submit" @click="register()">submit</button>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosRegister, axiosCoutry } from "@/api/register"
export default {
  created() {
    this.syncCountry();
  },
  data() {
    return {
      showCountryPrompt: false,// 初始时不显示提示
      formData: {},
      error: null,
      countryList: [],
      requiredData: {
        CompanyLegalName: true,
        CompanyRegistration: true,
        RegisteredCompanyAddress: true,
        BusinessPhoneNumber: true,
        PayableFirstName: true,
        PayableLastName: true,
        PayablePhoneNumber: true,
        PayableNameofBank: true,
        PayableBankSWIFT: true,
        Email: true,
      },
      Profile: [],
      
    }
  },
  methods: {
    syncCountry() {
      axiosCoutry().then((result) => {
        this.countryList = result;
      });
    },
    validateForm() {
      let result = true;
      for (let key in this.requiredData) {
        if (this.formData[key] == undefined) {
          result = false;
        } else if (this.formData[key].trim() == '') {
          result = false;
        }
      }
      return result;
    },
    getDownloadUrl(file) {
      const baseUrl = '/uploads';

      const fileName = file.name;

      const downloadUrl = `${baseUrl}/${fileName}`;

      return downloadUrl;
    },
    updateFilePath(event) {
      const selectedFile = event.target.files[0];
      this.Profile.push(selectedFile);
    },
    removeFile(key) {
      this.Profile.splice(key, 1);
    },
    register() {
      let formDatas = new FormData();
      for(let i = 0; i < this.Profile.length; i++) {
        formDatas.append("profile[]", this.Profile[i]);
      }
   
      for (let key in this.formData) {
        if (this.formData.hasOwnProperty(key)) {
          formDatas.append(key, this.formData[key]);
        }
      }

      if (this.validateForm()) {
        axiosRegister(formDatas).then((result) => {
          if (result.status === true) {
            this.$router.push('/authentication/SuccessPage');
            return false;
          } else {
            this.error = result.message;
          }
        });
      } else {
        this.showCountryPrompt = true;
      }
    },
    MailSameAsRegistered(Val) {
      this.formData.MailingSameAsRegistered = Val;
    },
   
  }
}
</script>
<style scoped>
  h3 {
    font-size: 16px;
    font-weight: 500;
  }

.title-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
  align-items: flex-end;
  text-align: center;
}

.title-header h4 {
  margin-bottom: -1px;
  margin-left: 30.5vw;
}

.title-line {
  width: 330px;
  height: 0.5vw;
  background-color: orange;
}

.first_name input {
  width: 24.2vw;
}

.Prompt {
  color: red;
  font-weight: 700;
  font-size: 12px;
}

input {
  text-decoration: none;
}

.business_second_row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;
}

label {
  color: rgb(31, 30, 30) !important;
  font-size: 16px !important;
  font-weight: 500 !important;

}

.tip {
  font-size: 10px;
  color: dimgray;
}

.address {
  margin-bottom: 10px;
}


.first_names {
  width: 31vw !important;
}

.first_names input {
  width: 29vw !important;
}

.first_fax {
  width: 36.5% !important;
}

.first_fax input {
  width: 76% !important;
}

.ragister_button {
  text-align: center;
  margin-top: 20px;
}

.btn_submit {
  text-transform: uppercase;
  font-weight: 700;
  width: 150px;
  height: 40px;
  border-radius: 15px;
  margin: 10px;
  border: 0;
  color: #ffffff;
  margin-bottom: 2vw !important;
}

.info {
  width: 80vw;
  color: #353434;
}

.ownershipn_whole {
  margin-top: 12px;
}

.ownership_first {
  display: flex;
}

.ownership_first .first_1 {
  display: flex;
  flex-direction: column;
}

.mailing,
.mailing_yes_open {
  margin-top: 16px;
}

.city,
.ZIP,
.Province,
.Country {
  width: 24%;
}

.city input,
.Province input,
.Country select,
.business_Address input,
.ZIP input {
  width: 95%;
}



.business_date,
.business_primary,
.business_dollars {
  margin-top: 16px !important;
}


.first_column {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.column_top {
  display: flex;
}

.column_top input {
  width: 11.5vw !important;
  margin-right: 10px;
}



.column_top span {
  font-size: 8px;
  color: darkgrey;
}

.second_column {
  margin-bottom: 10px;
}

.three_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  margin-top: 5px;
}

.three_column .artitle {
  width: 80vw;
  margin-bottom: 10px;
  font-size: 16px;
}

.three_column .arthead {
  width: 80vw;
  margin-bottom: 10px;
  font-size: 16px;
}

.four_column {
  display: flex;
  justify-content: space-between;
}


.four_column label {
  color: #5e5d5d;
}

.four_column input {
  width: 24vw !important;
  margin-right: 11px;
}

.layout {
  width: 50vw !important;
}

.four_top span {
  margin-top: -3vw;
}

.ownership_first {
  width: 80vw;
  display: flex;
  justify-content: space-between;
}

.ownership_first .first_1 {
  display: flex;
  flex-direction: column;
}

.ownershipn_whole {
  display: flex;
}

.hint {
  font-size: 8px;
  color: darkgrey;
}

.radio1 {
  display: flex;
  width: 20px;
  margin-left: 25px;
  align-items: center;
  justify-content: center;
}

.radio-first {
  margin-left: 26px;
}

.mb-1 {
  margin-left: 14px;
}

.ownership_three {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}


.subtitle {
  margin-top: -20px;
}

.shape {
  width: 40vw !important;
}

.PayableBankTel {
  margin-right: -11px;
}

.readyEmail,
.profile {
  margin-top: 12px;
}

.profile-area {
  margin: 10px;
}

.upload-profile {
  width: 48%;
}

.readyEmail input {
  width: 24vw;
}

.icofont-ui-delete {
  cursor: pointer;
}

@media(max-width:900px) {
  .title-header {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .title-header h4 {
    margin-right: 1vw !important;
  }

  .register-form h4 {
    margin-left: 0vw !important;
  }

  .form-label {
    height: 4vw;
  }

  .first_column label {
    height: 9vw;
  }

}

@media(max-width:520px) {
  .first_fax{
    width:50% !important;
  }

  .first_fax input{
    width:95% !important;
  }
  .first_fax label {
    height: 10.5vw;
  }

  .form-labels {
    height: 24vw;
  }

  .bankstyle label{
    height: 14vw;
  }
  .form-name {
    height: 12vw !important;
  }

  .four_column label {
    height: 24vw;
  }

  .first_name label {
    height: 12vw;
  }
  .upload-profile{
    width:100% !important;
  }
}
</style>