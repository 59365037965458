import {get,post,del} from "@/model/http"

export function getCartData(data) {
    return get('/api/v1/cart',data);
}


export function axiosUpdateCart(data) {
    return post('/api/v1/cart',data);
}

export function axiosCheckout(data) {
    return post('/api/v1/cart/checkout',data);
}

export function axiosDelCart(data) {
    return del('/api/v1/cart',data);
}

