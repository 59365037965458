<template>
  <div>
    <div>
      <div class="header">
        <h3>Account Balance</h3>
      </div>
      <div class="container-fluid">
       <div class="card">
          <div class="card-body row">
            <div class="col-sm-12 col-lg-12 col-xl-12">
                <label class="col-form-label">
                  Note: Backorders will be cleared twice per week on Tuesday and Thursday. Any credits due to cancellations will become available at that time. Recent purchases, payments and credits may take time to be reflected here.
                </label>
              <div class="header-note col-xs-12">
              <div class="balance-table">
                <div class="row">
                  <div class="col-xs-6 text-right text-orange">Pending
                    Orders:</div>
                  <div class="col-xs-6 text-orange">${{orderAmount}}</div>
                </div>
                <div class="row">
                  <div class="col-xs-6 text-right text-muted">Invoices, Payments and Credits:</div>
                  <div class="col-xs-6">${{invoice}}</div>
                </div>
                <div class="row">
                  <div class="col-xs-6 text-right text-muted">
                    <strong>Balance Owed:</strong>
                  </div>
                  <div class="col-xs-6">
                    <strong>${{balance}}</strong>
                  </div>
                </div>
              </div>
            </div>
              <table class="table">
                  <thead class="table-success">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Transaction</th>
                      <th scope="col">Order #</th>
                      <th scope="col">Status</th>
                      <th scope="col">Original Amount</th>
                      <th scope="col">Balance Owed</th>
                    </tr>
                  </thead>
                  <tbody v-if="paymentlist.length > 0">
                    <tr v-for="(pay, index) in paymentlist" :key="index">
                      <td>{{ pay.Date }}</td>
                      <td>INV</td>
                      <td>{{ pay.OrderID }}</td>
                      <td>{{ pay.Status }}</td>
                      <td>{{ pay.Amount }}</td>
                      <td>{{ pay.BalanceOwed }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <div class="Nodata">No data present</div>
                      </td>
                      
                    </tr>
                    
                  </tbody>
                </table>
                <!-- 分页 -->
                <div v-if="paymentlist.length > 0" class="job-pagination">
                  <nav aria-label="Page navigation example">
                    <div class="nav_left" v-if="paymentlist">
                      <div>Showing <span>{{ (params.page - 1) * pageSize }}</span>to<span>
                          {{ totalRecodes > pageSize ? params.page
                            *
                            pageSize : totalRecodes
                          }} </span>of<span> {{ totalRecodes }} </span>results
                      </div>
                    </div>
                    <ul class="pagination pagination-primary">
                      <li class="page-item disabled" @click="prePage()" :class="{ 'disabled': params.page == 1 }">
                        <a class="page-link" style="cursor: pointer;">Previous</a>
                      </li>
                      <li :class="[params.page == page ? 'page-item active' : 'page-item']" v-for="(page, index) in pages"
                        :key="index" @click="curPage(page)">
                        <a class="page-link" style="cursor: pointer;">{{ page }}</a>
                      </li>
                      <li class="page-item" :class="{ 'disabled': params.page == totalPage }" @click="next()">
                        <a class="page-link" style="cursor: pointer;">Next</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div v-else></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosGetPayment } from "@/api/payment"
export default {
  created() {
    this.syncData();
  },
  data() {
   return {
      params: {
        page: 1,//当前页
        pagesize: 10,//每一页需要显示的条数
      },
      paymentlist: [],
      orderAmount: 0,
      balance: 0,
      invoice: 0,
      drawpage: 1,
      totalRecodes: 0,
      pageSize: 10,
    };
  },
  computed: {
    pages() {
      let totalPage = Math.ceil(this.totalRecodes / this.pageSize); //总记录数/每页显示数 = 总页数
      this.totalPage = totalPage;
      this.params.page = this.drawpage;
      let page = this.params.page;
      if (totalPage < 10) return totalPage;
      if (page <= 2) {
        return [1, 2, 3, '...', totalPage]
      } else if (page >= totalPage - 3) {
        return [1, '...', totalPage - 1, totalPage]
      } else { // 中间五页
        return [1, '...', page - 1, page, page + 1, '...', totalPage]
      }
    },
  },
  methods: {
    syncData() {
      axiosGetPayment(this.params).then((result) => {
        this.paymentlist = result.data;
        this.drawpage = result.draw;
        this.totalRecodes = result.recordsTotal;
        this.pageSize = result.pageSize;
        this.orderAmount = result.orderAmount;
        this.balance = result.balance;
        this.invoice = result.balance;
      });
    },
    //中间
    curPage(page) {
      if (page == '...') return
      if (this.flag) return;
      this.params.page = page;
      this.syncData(this.params);
    },
    //上一页
    prePage() {
      if (this.params.page > 1) {
        if (this.flag) return;
        --this.params.page;
        this.syncData(this.params);
      }
    },
    //下一页
    next() {
      if (this.flag) return;
      // console.log("执行", this.totalPage)
      if (this.params.page == this.totalPage) {
        return false
      } else {
        ++this.params.page;
        this.syncData(this.params)
      }
    },
  },
}
</script>
<style scoped>
.header {
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top:12px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.Nodata{
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
}

.header-note {
  font-size: 17px;
  line-height: 25px;
}

@media(max-width:850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
}

.job-pagination nav ul {
  justify-content: right;
}
</style>