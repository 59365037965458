<template>
  <router-link to="/">
    <img class="img-fluid for-light" src="../../assets/images/logo/logo-2.png" style="width: 70px;" alt="" />
  </router-link>
</template>

<style scoped>
@media screen and (max-width: 480px) {
  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    padding: 1vw 0;
    width: 50px !important;
    height: 50px;
    }
  }
</style>