<template>
<div class="container-fluid">
    <div class="page-header">
      <div class="row">
        <div class="col-sm-6">
          <ol class="breadcrumb">
             <li class="breadcrumb-item" v-if="sub">{{ sub }}</li>
            <li class="breadcrumb-item" v-if="main">{{ main }}</li>
          </ol>
        </div>
         <div class="col-sm-6">
                  <div class="bookmark">
                    <ul>
                      <li style="padding:5px 10px;"><a href="javascript:void(0)">
                        <i class="icon-download" ></i>
                      </a></li>
                      <li style="padding:5px 10px;"><a href="javascript:void(0)">
                        <i class="icon-upload" ></i>
                      </a></li>
                      <li style="padding:5px 10px;"><a href="javascript:void(0)">
                        <i class="icon-shopping-cart" ></i>
                      </a></li>
                    </ul>
                  </div>
         </div>
      </div>
    </div>
</div>
</template>
<script>
 import searchForm from "./searchForm.vue"
export default {
  props: {
    title: {
      default: "home",
    },
    main: {
      default: "",
    },
    sub:{
      default:""
    }
  },
  components:{
    searchForm
  },
  data(){
    return{
       filtered: false,
       
    }
  },
   
  methods:{
     collapseFilter() {
        this.filtered = !this.filtered;
      },
  },
};
</script>
