import axios from 'axios'
import { load } from "@/components/loading/loading.js"

axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

axios.interceptors.request.use((config) => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
  }
  load.show();
  return config;
}, (err) => {
  return Promise.reject(err);
});

axios.interceptors.response.use(res => {
  load.hide();
  return Promise.resolve(res.data)
}, err => {
  load.hide();
  if (err.response) {
    switch (err.response.status) {
      case 401:
        window.location.href = '/auth/login'
        break
      case 404:
        //window.location.href='/login/out'
        break
      case 405:
        console.log('不支持的方法')
        break
      default:
        console.log('其他错误')
        break
    }
  }
  return Promise.reject(err)
})

export default axios;