<template>
    <!-- <Breadcrumbs main="Users" title="Users Edit"/> -->
    <div class="container-fluid">
            <div class="edit-profile ">
              <div class="row">
                  <myProfile/>
                  <editProfilePart/>
              </div>
            </div>
    </div>
</template>
<script>
import myProfile from "./myProfile.vue"
import editProfilePart from "./editProfilePart.vue"
export default {
    components:{
        myProfile,
        editProfilePart
    }
}
</script>