<template>
  <div>
    <div>
      <div class="header">
        <h3>Shipping and Billing Preferences</h3>
      </div>
      <div class="container-fluid">
        <div class="card">
          <div class="card-body row">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <label class="col-form-label">
                This information will be used as defaults during checkout and to fullfill orders created from awarded
                auction lots. For Stock List orders you will have the option of changing your selections during checkout.
              </label>
            </div>
            <div>
              <div class="col-sm-12 col-lg-12 col-xl-12 address-area">
                <div class="col">
                  <div class="mb-6 row">
                    <label class="col-sm-3 col-form-label">Shipping Address</label>
                    <div class="col-sm-3">
                    </div>
                    <label class="col-sm-3 col-form-label">Billing Address</label>
                    <div class="col-sm-3">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="col">
                  <div class="mb-6 row">
                    <label class="col-sm-4 col-form-label" v-if="shipping">{{ shipping.ShippingAddress }}</label>
                    <div class="col-sm-2">
                    </div>
                    <label class="col-sm-4 col-form-label" v-if="shipping">{{ shipping.BillingAddress }}</label>
                    <div class="col-sm-3">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12 address-area">
                <div class="col">
                  <div class="mb-6 row">
                    <label class="col-sm-4 col-form-label">Shipping<sup class="EditShipping"
                       ><router-link to="/account/preferenceShipping">Edit</router-link>
                      </sup><br /> Method
                    </label>
                    <div class="col-sm-2">
                    </div>
                    <label class="col-sm-4 col-form-label">Payment<sup class="EditShipping"
                       ><router-link to="/account/preferencePayMethod">
                          Edit</router-link></sup><br /> Method</label>
                    <div class="col-sm-3">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="col">
                  <div class="mb-6 row">
                    <label class="col-sm-4 col-form-label" v-if="shipping">{{shipping.ShippingMethod}}({{ shipping.Carrier  }})</label>
                    <div class="col-sm-2">
                    </div>
                    <label class="col-sm-4 col-form-label" v-if="shipping">{{ shipping.PaymentMethod }}</label>
                    <div class="col-sm-3">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosShipping} from "@/api/Shipping"
export default {
  created() {
    this.syncData();
  },
  data() {
    return {
      shipping: null,
    };
  },
  methods: {
    syncData() {
      axiosShipping().then((result) => {
        this.shipping = result.data;
        console.log(this.shipping);
      });
    },
  }
}
</script>
<style scoped>
.header {
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 12px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.header-note {
  font-size: 17px;
  line-height: 25px;
}

.address-area {
  margin-top: 50px;
}

.EditShipping {
  color: blue;
  font-size: 16px;
  font-weight: 600;
  margin-left: 12px;
}

@media(max-width:850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
  #shipping{
    height:6vw;
  }
}
</style>