<template>
  <div>
    <div class="header">
      <h3>Alert Settings</h3>
    </div>
    <div v-if="alertList.length > 0" class="container-fluid">
      <div class="card" v-if="delAlert" v-for="(alert, index) in alertList">
        <div class="card-body">
          <div class="FullDescription">
            {{ alert.FullDescription }}
          </div>
          <div class="avail">
            Avail: <span class="num">{{ alert.avail ? alert.avail : '-' }}</span>
          </div>
          <div class="quality_price">
            <div class="line">
              <i class="icofont icofont-bell-alt i_bells"></i>
              <div class="quantity">Alert me when you add
                <input type="number" :min="1" step="1" v-model="alert.quantity" />units
              </div>
              <div class="price">  and price:
                <input type="number" :min="1" step="1" v-model="alert.price" />
              </div>
            </div>
            <div class="erro">{{ message }}</div>
            <div class="btn_area">
              <a class="text-warning" @click="removeAlerts(alert)">REMOVE</a>
              <button class="btn_up" @click="updateAlert(alert)">UPDATE</button>
            </div>
          </div>
          <hr class="horizontalLine">
        </div>
      </div>

    </div>
    <div v-else>
      <p class="Nodata">No data present</p>
    </div>
  </div>
</template>
<script>
import { axiosGetAlert, axiosUpdateAlert, axiosDelAlert } from "@/api/alert"
export default {
  created() {
    this.syncData();
  },
  data() {
    return {
      alertqty: [],
      alertList: [],
      delAlert: true,
      message: '',
    };
  },
  computed: {

  },
  methods: {
    syncData() {
      axiosGetAlert(this.params).then((result) => {
        this.alertList = result.data;
      });
    },
    // //Alert编辑
    updateAlert(alert) {
      let item = { "id": alert.itemNumber, "quantity": alert.quantity, "price": alert.price };
      axiosUpdateAlert(item).then(rep => {
        this.syncData();
      });

    },
    // //alert删除方法
    removeAlerts(alert) {
      let item = { "id": alert.itemNumber }
      axiosDelAlert(item).then(rep => {
        this.syncData();
      });
    }
  }
}
</script>
<style scoped>
h1 {
  color: #003366;
  text-align: center;
}
.Nodata{
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
  height: 5vw;
  line-height: 6vw;
}
.container-fluid{
  display: flex;
  justify-content: space-evenly;
}
.card {
  font-size: 17px;
  width:48%;
}

.card-body {
  padding: 10px 30px !important;
}
.line{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.title {
  margin-bottom: 12px;
}

.icofont {
  color: #0070b9;
}

.line {
  display: flex;
}

.text {
  font-size: 17px;
  color: #0070b9;
}

.horizontalLine {
  border: 1px solid #d4ebf4;
}

.FullDescription {
  font-size: 14px;
  font-weight: 500;
  min-height: 75px;
}

.avail {
  color: #739199;
  margin: 10px 0;
  font-size: 14px;
}

.num {
  color: black;
  font-size: 20px;
}

.erro {
  font-size: 16px;
  font-weight: 500;
  color: red;
}
.quantity,.price{
  font-size: 14px;
  font-weight: 500;
}
.quantity input,
.price input {
  width: 60px;
  height: 30px;
  padding: 2px 5px;
}

.text-warning {
  margin: 0 10px;
  cursor: pointer;
  font-size: 13px;
}

.btn_up {
  width: 120px;
  padding: 7px;
  border-radius: 17px;
  font-size: 13px;
  margin-top: 12px;
  color: white;
  background-color: #0070b9;
  border: 0;
}

.btn_area {
  text-align: right;
  display: block;
}

.header {
  justify-content: space-between;
  margin-bottom: 5px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

@media(max-width:850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
}
@media(max-width:700px){
  .container-fluid{
    display: block   !important;
  }
  .card {
    font-size: 17px;
    width:100%;
  }
}
</style>