<template>
<div v-show="msg.show">
    <div class="text-center loading-area">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="loading-cover">
  </div>
</div>
</template>
<script>
export default {
  name: 'loading',
  props: {
    msg: Object,
  }
}
</script>
<style>
.loading-area{
  top:40%;
  left:50%;
  position: fixed;
  z-index:1001;
}

.spinner-border{
    width: 100px !important;
    height: 100px !important;
    font-weight: bold;
    font-size: 50px;
    color: #3898ab;
}

.loading-cover{
  position: fixed !important;
  background: #000000;
  width: 100% !important;
  height: 100% !important;
  opacity: 0.8 !important;
  z-index: 1000;
  top:0 !important;
  left: 0 !important;
}

</style>