<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12 ">
        <div class="login-card">
          <div class="theme-form login-form">
            <h4>Confirm your password</h4>
            <div class="form-group" v-if="!resetSuccess">
              <label>New Password</label>
              <div class="input-group"><span class="input-group-text"><i class="icon-lock"></i></span>
                <input class="form-control" :type="type" v-model="password" required="" placeholder="*********">
                <div class="show-hide"><span class="show" @click="showPassword"></span></div>
              </div>
            </div>
            <div class="form-group" v-if="!resetSuccess">
              <label>Rtype Password</label>
              <div class="input-group"><span class="input-group-text"><i class="icon-lock"></i></span>
                <input class="form-control" :type="type" v-model="confirmPassword" required="" placeholder="*********">
              </div>
            </div>
            <div class="message-area">
              <div v-if="error" class="error">{{ error }}</div>
              <div v-if="resetSuccess" class="success">Reset successfully<a class="ms-2" href="/auth/login">Sign in</a></div>
            </div>
            <div class="form-group" v-if="!resetSuccess"><a class="btn btn-primary btn-block" type="submit" @click="submitPassword">Submit</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosUpdatePasswordProfile } from "@/api/userprofile"

export default {
  name: 'reset_password',
  data() {
    return {
      type:'password',
      password: '',
      confirmPassword: '',
      error: '',
      resetSuccess: false
    };
  },
  methods: {
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    submitPassword() {
      if(this.password != this.confirmPassword) {
        this.error = 'password not the same';
        return false;
      }
      let token = this.$route.query.token;
      localStorage.setItem('token',token)
      axiosUpdatePasswordProfile({password:this.password}).then((result) => {
        if(result.status) {
          this.resetSuccess = true;
          this.error = '';
        } else {
          this.resetSuccess = false;
          this.error = result.message;
        }
      });
    }
  }
};
</script>
<style scoped>
.error {
  margin: 10px;
  color: red;
}

.success {
  color: green;
}

.message-area {
  padding:10px;
}
</style>