<template>
  <div>
    <div>
      <div class="header">
        <h3>DLS GRADING SCALE</h3>
      </div>
      <div class="container-fluid">
        <div class="card">
          <div class="card-body row">
            <div class="textblock">
              <div>
                Devices are thoroughly inspected and receive a grade based on
                cosmetic condition and functionality.
              </div>

              <div>Grading scales are specific to each device category:</div>

              <div>Smartphones and Tablets Grades</div>

              <div>
                Here is a full breakdown of our stock grades with example videos.
              </div>

              <div>
                All devices have the OEM locks disabled and have been data
                cleared.
              </div>
            </div>
            <div class="flex_column_table">
              <div class="flex_column_table_cell first">
                <h3 class="av-special-heading-tag" itemprop="headline">
                  AA+
                </h3>
              </div>
              <div class="flex_column_table_cell second">
                <h4 class="av-special-heading-tag" itemprop="headline">
                  Like New, Fully Functional Devices
                </h4>
                <div class="special-heading-border">
                  <div class="special-heading-inner-border"></div>
                </div>
                <section
                  class="av_textblock_section"
                  itemscope="itemscope"
                  itemtype="https://schema.org/CreativeWork"
                >
                  <div class="avia_textblock" itemprop="text">
                    <p>
                      Like-new devices from Buyer’s Remorse programs typically
                      returned by consumers within 14 days of purchase.
                    </p>
                  </div>
                </section>
              </div>
              <div class="flex_column_table_cell third">
                <a href="https://vimeo.com/477233641?autoplay=1">
                  <img
                    src="https://hylaasp.hylamobile.com/wp-content/uploads/Thumbnail-AA-e1645807578488-300x168.png"
                    alt=""
                    title="Thumbnail AA+"
                    itemprop="thumbnailUrl" />
                </a>
              </div>
            </div>
            <div class="flex_column_table">
              <div class="flex_column_table_cell first">
                <h3 class="av-special-heading-tag" itemprop="headline">
                  A+
                </h3>
              </div>
              <div class="flex_column_table_cell second">
                <h4 class="av-special-heading-tag" itemprop="headline">
                  Light Wear and Tear, Fully Functional Devices
                </h4>
                <div class="special-heading-border">
                  <div class="special-heading-inner-border"></div>
                </div>
                <section
                  class="av_textblock_section"
                  itemscope="itemscope"
                  itemtype="https://schema.org/CreativeWork"
                >
                  <div class="avia_textblock" itemprop="text">
                    <p>
                      Devices have passed all functional tests performed and may exhibit some minor cosmetic blemishes.
                    </p>
                  </div>
                </section>
              </div>
              <div class="flex_column_table_cell third">
                <a href="https://vimeo.com/477232307?autoplay=1">
                  <img
                    src="https://hylaasp.hylamobile.com/wp-content/uploads/Thumbnail-A-1-e1645807562275-300x168.png"
                    alt=""
                    title="Thumbnail A+"
                    itemprop="thumbnailUrl" />
                </a>
              </div>
            </div>
            <div class="flex_column_table">
              <div class="flex_column_table_cell first">
                <h3 class="av-special-heading-tag" itemprop="headline">
                  B+
                </h3>
              </div>
              <div class="flex_column_table_cell second">
                <h4 class="av-special-heading-tag" itemprop="headline">
                  More Aggressive Wear and Tear, Fully Functional
                </h4>
                <div class="special-heading-border">
                  <div class="special-heading-inner-border"></div>
                </div>
                <section
                  class="av_textblock_section"
                  itemscope="itemscope"
                  itemtype="https://schema.org/CreativeWork"
                >
                  <div class="avia_textblock" itemprop="text">
                    <p>
                      Devices have passed all functional tests performed. They will exhibit more noticeable cosmetic blemishes.
                    </p>
                  </div>
                </section>
              </div>
              <div class="flex_column_table_cell third">
                <a href="https://vimeo.com/477232307?autoplay=1">
                  <img
                    src="https://hylaasp.hylamobile.com/wp-content/uploads/Thumbnail-B-1-e1645807624793-300x168.png"
                    alt=""
                    title="Thumbnail A+"
                    itemprop="thumbnailUrl" />
                </a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {},
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top:12px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.textblock {
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 20px;
}

.flex_column_table {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: baseline;
  border: 1px solid #FFFFFF;
  background-color: rgba(98,167,15,0.00);
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
  padding: 10px;
  margin-bottom: 30px;
}

.flex_column_table .first {
  width: 20%;
}

.flex_column_table .first h3 {
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.flex_column_table .second {
  width: 60%;
}

.flex_column_table .second p {
  margin: 5px 0;
  font-size: 20px;
}

.flex_column_table .third {
  width: 20%;
}

.flex_column_table_cell {
  float: none;
  display: table-cell;
}

.flex_column_table_cell img {
  vertical-align: top;
  width:100%;
}

@media (max-width: 850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
}

.job-pagination nav ul {
  justify-content: right;
}
</style>
