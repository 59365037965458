<template>
  <div>
    <div>
      <div class="header">
        <h3>Notification Preferences</h3>
      </div>
      <div class="container-fluid" v-show="notification">
        <div class="card">
          <div class="card-body row">
            <div>
              <div class="row">
                <div class="col-xs-12">
                  <h5 class="pull-left">Notify on changes in Order Status:</h5>
                </div>
              </div>
              <div class="row option">
                <div class="col-xs-12">
                  <div class="checkbox-animated checkbox-animated-ostock">
                    <label class="d-block" :for="enableInOrderNotification">
                      <input
                        type="checkbox"
                        class="checkbox_animated"
                        name="enableInOrderNotification"
                        v-model="notification.enableInOrderNotification"
                        :checked="notification.enableInOrderNotification"
                        :id="enableInOrderNotification"
                      />
                      <span>When my order is created or updated</span>
                    </label>
                  </div>
                </div>
                <div class="col-xs-12">
                   <div class="checkbox-animated checkbox-animated-ostock"> 
                    <label class="d-block" :for="enableInFulfillmentNotification">
                      <input
                        class="checkbox_animated"
                        type="checkbox"
                        name="enableInFulfillmentNotification"
                        v-model="notification.enableInFulfillmentNotification"
                        :checked="notification.enableInFulfillmentNotification"
                        :id="enableInFulfillmentNotification"
                      />
                      <span>When my order is in fulfillment</span>
                    </label>
                  </div>
                </div>
                <div class="col-xs-12">
                  <div class="checkbox-animated checkbox-animated-ostock"> 
                    <label class="d-block" :for="enableFulfilledNotification">
                      <input
                        class="checkbox_animated"
                        type="checkbox"
                        name="enableFulfilledNotification"
                        v-model="notification.enableFulfilledNotification"
                        :checked="notification.enableFulfilledNotification"
                        :id="enableFulfilledNotification"
                      />
                      <span
                        >When my order has been Picked and is awaiting carrier
                        pickup</span
                      >
                    </label>
                  </div>
                </div>
                <div class="col-xs-12">
                  <div class="checkbox-animated checkbox-animated-ostock"> 
                    <label class="d-block" :for="enableShippedNotification">
                      <input
                        class="checkbox_animated"
                        type="checkbox"
                        name="enableShippedNotification"
                        v-model="notification.enableShippedNotification"
                        :checked="notification.enableShippedNotification"
                        :id="enableShippedNotification"
                      />
                      <span>When my order has been shipped</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <h5 class="pull-left">
                    Daily Report on changes to Stock List prices and
                    availability:
                  </h5>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <div class="checkbox-animated checkbox-animated-ostock"> 
                      <label class="d-block" :for="enableStockReportNotification">
                        <input
                          class="checkbox_animated"
                          type="checkbox"
                          name="enableStockReportNotification"
                          v-model="notification.enableStockReportNotification"
                          :checked="notification.enableStockReportNotification"
                          :id="enableStockReportNotification"
                        />
                        <span
                          >Receive daily spreadsheet report on all new inventory
                          availablity and price drop
                          <span class="text-muted">(Mon - Fri)</span></span
                        >
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 btn-area">
                  <input
                    class="btn btn-primary btn-lg preference-button"
                    type="submit"
                    value="Save Preferences"
                    @click="save()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosGetNotification, axiosEditNotification } from "@/api/notification"
export default {
  created() {
    this.syncData();
  },
  data() {
    return {
      notification: [],
    };
  },
  methods: {
    syncData() {
      axiosGetNotification().then((result) => {
        this.notification = result;
      });
    },
    save() {
      axiosEditNotification(this.notification).then((result) => {
      });
    }
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top:12px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.checkbox-animated-ostock{
  padding:0 22px;
  cursor: pointer;
}

.checkbox-animated {
  margin-bottom: 30px;
}


@media (max-width: 850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
}

.job-pagination nav ul {
  justify-content: right;
}

.btn-area {
  text-align: right;
}
</style>
