import { createApp, reactive } from 'vue'

import myLoad from '@/components/loading/loading.vue'

const msg = reactive({
  show: false,
})

const $loading = createApp(myLoad, {msg}).mount(document.createElement('div'))
const load = {
  show() { 
    msg.show = true
    document.body.appendChild($loading.$el)
  },

  hide() { 
    msg.show = false
  }
}
export  { load }