<template>
  <div class="header">
    <div>
      <h3>Stock Listings</h3>
    </div>
    <div class="header_right">
      <div class="right_align">
        <div>
          <i class="icofont icofont-upload-alt icon_style"></i>
          <a class="icon_text" @click="showImports()">IMPORT</a>
        </div>
        
      </div>
      <div class="right_center">
        <i class="icofont icofont-download-alt icon_style"></i>
        <div class="dropdown-basic export">
          <div class="dropdown">
            <a class="icon_text">Dropdown Button</a>
            <div class="dropdown-content">
              <a @click="downloadFiles">EXPORT ALL ITEMS</a>
              <a @click="downloadFilesByFilter">EXPORT FILTERED ITEMS</a>
            </div>
          </div>
        </div>
      </div>
      <div class="right_align">
        <i class="icofont icofont-bell-alt icon_style"></i>
        <RouterLink class="icon_text" to="/alert_list">ALTER SETTING</RouterLink>
      </div>
      <div class="right_rt">
        <i class="icofont icofont-shopping-cart icon_style"></i>
        <RouterLink class="icon_text" to="/cart_list">CART<sup>{{ offers }}</sup></RouterLink>
      </div>
    </div>
    
  </div>
  <div class="itemsquantity">
    <div class="updateTime">Last Updated : &nbsp;<span>{{ lastUpdated }}</span></div>
    <div class="qty_items">
      <span>
        ltems: &nbsp;<span>{{ itemQuantityFormatted }}</span>
      </span>
      <span class="qtys">Qty: &nbsp;<span>{{ quantityTotalFormatted }}</span></span>
    </div>
      

    
  </div>
  <!-- import弹窗 -->
  <div v-if="showImport">
    <div class="popup">
      <div class="import_top">
        <div>
          <span class="text">lmport Buy and Offer Requests</span>
        </div>
        <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
      </div>
      <div class="import_center">
        <div class="center_title">To import your Buy and Offer Requests</div>
        <button class="choose">
          <div class="choose_text">CHOOSE FILE TO IMPORT</div>
          <input type="file" class="upload-file" @change="handleFileChange" ref="fileInput" />
          <div class="version">{{ selectedFolder }}</div>
        </button>
        <div class="fileFormat">XLSX format</div>
      </div>
      <div class="import_middle">
        <p class="middle_tip">Your file must include columns for Warehouse SKU, New OfferQuantity, and New Offer Price.
        </p>
        <p class="middle_tip">Processing files with a large number of offers (500+) can takea few minutes.</p>
      </div>
      <div v-if="error" class="errormessage">{{ errorMessage }}</div>
      <!-- 文件上传后的提示信息 -->
      <div v-if="toggletips" class="tipss" @wheel="handleScroll" style="height: 135px;">
        <div class="question" style="display:flex;">
          <i class="fa fa-question"></i>
          <p>lnvalid Buy and Offer Requests: Correct the followincrows in your file and import again.</p>
        </div>
        <div>
          <ul v-for="(k, value) in importMessage" :key="value">
            <li> {{ k }}</li>
          </ul>
        </div>


      </div>
      <div class="import_bottom">
        <button class="bn_cel" @click="cannel">CANCEL</button>
        <label v-if="clears" style="margin-left:10px;">
          <!-- :disabled="!this.file" -->
          <button v-if="!submit" class="bn_rt" @click="imports()">IMPORT</button>
          <button v-else class="bn_sm" @click="submitfile()">SUBMIT</button>
        </label>

        <div v-else></div>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <product_tab />
      <div v-if="jobslist == ''" class="col-xl-9 xl-70 box-col-8">
        <p class="Nodata">No data present</p>
      </div>
      <div v-else class="col-xl-9 xl-70 box-col-8">
        <div class="card" v-for="(job, index) in jobslist" :key="index">
          <div class="job-search">
            <div class="card-body">
              <div class="ribbon ribbon-secondary">{{ job.warehouse }}</div>
              <div class="valuename">
                <p class="title1">
                  {{ job.description }} ({{ job.Capacity }})
                  <label>
                    <span class="badge badge-primary">Items: {{ job.aggregatedCountFormatted }}</span>
                    <span class="badge badge-primary">Qty: {{ job.aggregatedQuantityFormatted }}</span>
                    <span class="badge badge-primary">Price: {{ job.aggregatedPriceFormatted }}</span>
                  </label>
                </p>
                <p class="title2">{{ job.grade }}</p>
              </div>
              <div class="colorname" v-for="(k, iteminfo) in job.items">{{ k.groupBy }}</div>
              <product_btn :job="job" />
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="job-pagination" v-if="totalRecodes">
          <nav aria-label="Page navigation example">
            <div class="nav_left" v-if="jobslist">
              <div>Showing <span>{{ (params.page - 1) * pageSize + 1 }}</span>to<span>{{ totalRecodes > pageSize &&
                params.page
                *
                pageSize < totalRecodes ? params.page * pageSize : totalRecodes }}</span>of<span>{{ totalRecodes
  }}</span>results
              </div>
            </div>
            <ul class="pagination pagination-primary">
              <li class="page-item" @click="prePage()" :class="{ 'disabled': params.page == 1 }">
                <a class="page-link" style="cursor: pointer;">Previous</a>
              </li>
              <li :class="[params.page == page ? 'page-item active' : 'page-item']" v-for="(page, index) in pages"
                :key="index" @click="curPage(page)">
                <a class="page-link" style="cursor: pointer;">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ 'disabled': params.page == totalPage }" @click="next()">
                <a class="page-link" style="cursor: pointer;">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { axiosGetImportProducts, axiosSubmitOffer } from "@/api/product"
import { mapState, useStore, mapActions } from 'vuex';
import product_tab from "./product_tab.vue";
import product_btn from "./product_btn.vue";
import axios from "axios";
export default {
  created() {
    this.getAllJob(this.params);

  },
  components: {
    product_tab,
    product_btn,
  },
  data() {
    return {
      params: {
        page: 1,//当前页
        pagesize: 10,//每一页需要显示的条数
        name: '',
        includOutStock: null,
        selectedFilters: null,
      },
      list: [],
      total: 1,//每一页显示的条数
      totalPage: 1,//总页数
      flag: false,
      active: false,
      showPopup: false,
      showImport: false,
      selectedFolder: '',
      toggletips: false,
      submit: false,
      clears: true,
      importMessage: [],
      backData: [],
      file: null,
      backData: [],
      error: false,
      errorMessage: 'Please select a file first',
    };
  },
  computed: {
    ...mapState({
      cartList: state => { return state.cart.cartData },
      alertList: state => { console.log('result-xxx', state.alert.alertData); return state.alert.alertData },
      drawpage: state => state.jobs.draw,
      jobslist: state => state.jobs.jobsData,
      totalRecodes: state => state.jobs.totalRecords,
      pageSize: state => state.jobs.pageSize,
      offers: state => state.jobs.offers,
      lastUpdated: state => state.jobs.lastUpdated,
      includOutStock: state => state.jobs.includOutStock,
      selectedFilters: state => state.jobs.selectedFilters,
      itemQuantityFormatted: state => state.jobs.itemQuantityFormatted,
      quantityTotalFormatted: state => state.jobs.quantityTotalFormatted,
    }),
    pages() {
      let totalPage = Math.ceil(this.totalRecodes / this.pageSize); //总记录数/每页显示数 = 总页数
      this.totalPage = totalPage;
      this.params.page = this.drawpage;
      let page = this.params.page;
      if (totalPage < 10) return totalPage;
      if (page <= 2) {
        return [1, 2, 3, '...', totalPage]
      } else if (page >= totalPage - 3) {
        return [1, '...', totalPage - 1, totalPage]
      } else { // 中间五页
        return [1, '...', page - 1, page, page + 1, '...', totalPage]
      }
    },
  },
  methods: {
    ...mapActions('jobs', ['getAllJob'], ['showPopup'], ['selectedFilters']),

    stars(count) {
      var stars = '';

      for (var i = 0; i < 5; i++) {
        if (count > i) {
          stars = stars + '<i class="fa fa-star font-warning"></i>';
        } else {
          stars = stars + '<i class="fa fa-star-o font-warning"></i>';
        }
      }

      return stars;
    },
    //中间
    curPage(page) {
      if (page == '...') return
      if (this.flag) return;
      this.params.page = page;
      this.getAllJob(this.params);
    },
    //上一页
    prePage() {
      if (this.params.page > 1) {
        if (this.flag) return;
        --this.params.page;
        this.getAllJob(this.params);
      }
    },
    //下一页
    next() {
      if (this.flag) return;
      // console.log("执行", this.totalPage)
      if (this.params.page == this.totalPage) {
        return false
      } else {
        ++this.params.page;
        this.getAllJob(this.params)
      }
    },
    showImports() {
      this.submit = false;
      this.showImport = !this.showImport;
    },
    hidePopup() {
      this.selectedFolder = '';
      this.showImport = false;
      this.toggletips = false
      this.clears = true;
      this.error = false;
    },
    handleFileChange(event) {
      // console.log(event);
      let file = event.target.files[0]
      if (file) {
        this.selectedFolder = file.name;
        this.error = false;
      }
    },
    cannel() {
      this.selectedFolder = '';
      this.showImport = false
      this.toggletips = false
      this.clears = true;
      this.error = false;
    },
    //文件上传
    imports() {
      let fileInput = this.$refs.fileInput;
      this.file = fileInput.files[0];
      if (this.file) {
        this.error = false;
        this.importProducts();
      } else {
        this.error = true;
      }


    },
    dealData() {
      this.importMessage = [];
      let backData = this.backData;
      console.log(backData);
      this.selectedFolder = '';
      if ((!backData.modifiedOfferMessages.length && !backData.invalidOfferMessages.length) && backData.totalOffersToBeImported > 0) {
        this.getAllSubmit();
        this.hidePopup();
      }
      if (backData.invalidOfferMessages) {
        for (let key in backData.invalidOfferMessages) {
          this.importMessage.push(backData.invalidOfferMessages[key]);
        }
        this.toggletips = true;
      }

      if (backData.modifiedOfferMessages.length || backData.totalOffersToBeImported > 0) {
        this.submit = true;
        this.toggletips = true;
        if (backData.modifiedOfferMessages.length) {
          for (let key in backData.modifiedOfferMessages) {
            this.importMessage.push(backData.modifiedOfferMessages[key]);
          }
        }
      }

      console.log(this.importMessage);

      if (!(backData.modifiedOfferMessages.length) && backData.totalOffersToBeImported == 0) {
        this.clears = false;
        this.toggletips = true;
      }
    },
    importProducts() {
      let formData = new FormData();
      formData.append('file', this.file);
      axiosGetImportProducts(formData).then((result) => {
        console.log(result);
        this.backData = result;
        if (this.backData.error == undefined) {
          this.error = false;
          this.dealData();
        } else {
          this.error = true;
          this.errorMessage = this.backData.message;
        }
      });
    },
    submitfile() {
      this.getAllSubmit();
      this.hidePopup();
    },
    getAllSubmit() {
      axiosSubmitOffer().then((result) => {
        this.getAllJob(this.params);
      });
    },
    //全部文件下载
    downloadFiles() {
      let downloadUrl = 'http://api.stellatronic.com/export/products';

      let newWindow = window.open('', '_blank');

      let downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute('download', 'filename.ext');

      downloadLink.click();

      newWindow.close();
    },
    downloadFilesByFilter() {
      let cat = ''
      for (let key in this.selectedFilters) {
        if (Array.isArray(this.selectedFilters[key])) {
          this.selectedFilters[key].forEach(value => {
            cat += `${key}[]=${encodeURIComponent(value)}&`;
          });
        }
      }
      let downloadUrl = 'http://api.stellatronic.com/export/products?' + cat;
      if (this.includOutStock) {
        downloadUrl += `&includOutStock=1`;
      }

      let newWindow = window.open('', '_blank');

      let downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute('download', 'filename.ext');

      downloadLink.click();

      newWindow.close();
    },
    //鼠标滑轮事件
    handleScroll(event) {
      if (event.deltaY > 0) {
      } else if (event.deltaY < 0) {
      }
    }
  }
}
</script>
<style scoped>
.ribbon-secondary {
  background-color: #4f7daa;
}

.Nodata {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateTime {
  margin-left: 15px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #739199;
}
.qty_items{
  display: flex;
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #739199;
}
.qtys{
  margin-left: 12px;
}
.itemsquantity {
  width: 47%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.export {
  margin-left: 30px;
  margin-top: -24px;
}


.card {
  margin-bottom: 15px !important;
}


.card .card-body {
  padding: 25px 25px 0px 25px;
}

.job-search p {
  margin-top: 15px;
  margin-bottom: 0;
}

.dropdown-basic-export {
  padding: 12px 0px;
  font-size: 16px;
  font-weight: 700;
  color: #3898ab;
  cursor: pointer;
  background: none;
}


.badge-primary {
  background: #3898ab;
}

.dropdown-content {
  z-index: 100;
  margin-top: -1.6vw;
}

.dropdown-content a {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.header_right {
  width: 55vw;
  display: flex;
  justify-content: space-around;
}

.errormessage {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: red;
  margin-bottom: 10px;
}

.icon_style {
  font-size: 23px;
  font-weight: 700;
  color: #3898ab;
}

.icon_text {
  width: 8vw;
  font-size: 16px;
  margin-left: 1vw;
  margin-top: -2vw;
  font-weight: 700;
  color: #3898ab;
  position: relative;
  cursor: pointer;
}

.clear {
  height: 10px;
  background-color: #f5f7fb;
}

.back {
  width: 200vw;
  background-color: #666666;
}

.popup {
  position: fixed;
  top: 5%;
  left: 30%;
  width: 40%;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 101;
  margin: 30px auto;
}

.popup-cover {
  background: #000000;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.import_top {
  display: flex;
  justify-content: space-between;
}

.text {
  font-size: 18px;
  color: #111111;
  font-weight: 700;
}

.fileFormat {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.center_title {
  text-align: center;
  margin: 1vw 0;
  font-size: 16px;
  color: #2a2c2c;
  font-weight: 500;
}

.choose {
  background-color: #ffffff;
  width: 70%;
  height: 34px;
  font-size: 16px;
  color: white;
  position: relative;
  border-radius: 15px;
  border: 0;
  margin-left: 4%;
  margin-bottom: 2vw;
}



.choose_text {
  font-size: 16px;
  background-color: #0070b9;
  color: white;
  position: relative;
  border-radius: 15px;
  border: 0;
  margin-left: 30%;
  margin-bottom: 2vw;
}

.choose .upload-file {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0vw;
}

.version {

  width: 50vw;
  margin-top: -2vw;
  color: black;
  margin-left: -8.5vw;

}

.middle_tip {
  text-align: center;
  font-size: 14px;
  color: #2b2c2c;
  font-weight: 500;
}

.import_bottom {
  text-align: center;
}

.bn_cel,
.bn_rt,
.bn_sm {
  color: white;
  border-radius: 25px;
  border: 0;
  padding: 10px 20px;
}

.bn_cel {
  background-color: #739199;
}

.bn_rt {
  background-color: #0070b9;
}

.bn_sm {
  background-color: green;
}

.pagination {
  margin: -3.5vw 0 0 24vw;
}


.right_lf,
.right_center,
.right_align,
.right_rt {
  height: 6vh;
  margin-top: 15px;
}

.valuename {
  display: flex;
  justify-content: space-between;
}

.valuename .title1 {
  font-size: 16px;
  font-weight: 700;
  color: #3a494d;
}

.valuename .title2 {
  font-size: 16px;
  font-weight: 700;
  color: #3a494d;
}

.colorname {
  font-size: 18px;
  color: #739199;
  font-weight: 500;
  margin-bottom: 8px;
}

.grade {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 8px;
  width: 45vw;
}

.lineheigth {
  display: flex;
  flex-direction: column;
}

.headline {
  display: flex;
  justify-content: space-evenly;
}

.headlines {
  display: flex;
}

.body_title {
  display: flex;
  margin-top: 22px;
}

.items,
.qty,
.price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 7vw;
}

.subtitles1 {
  font-size: 6px;
}

.subtitles {
  font-size: 20px;
}

.tipss {
  position: relative;
  border: 2px solid red;
  margin-bottom: 1vw;
  width: 100%;
  overflow-y: scroll;
}

.question {
  position: sticky;
  font-size: 16px;
}

.whole {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 37vw;
}

.artitler,
.artitles,
.artitle,
.artitled {
  font-size: 17px;
  font-weight: 500;
  color: #3a494d;
  margin-bottom: 8px;
}

.artitler {
  margin-left: 63px;
}

.artitled {
  margin-left: 48px;
}



.artitlevalue {
  font-size: 20px;
  font-weight: 500;
  color: #3a494d;
}

.btn_style {
  height: 40px;
  border-radius: 20px;
  margin: 19px 0px 0px 12px !important;

}

.i_bell {
  font-size: 25px;
  color: blue;
  height: 25px;
  border-radius: 10px;
  margin: 34px -15px 0px 12px;
}

sup {
  margin-left: 10px;
  color: red;
}

.nav_left {
  font-size: 12px;
  margin: 13px 0 55px 10px;
}

.nav_left span {
  margin: 0 10px;
}

.job-pagination {
  margin: 40px 0;
}

.pagination-primary .page-item.active .page-link {
  background-color: #3898ab !important;
}

.job-pagination nav ul {
  justify-content: right;
}

@media(max-width:850px) {
  .itemsquantity{
    width:75% !important;
  }
  .header {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }

  .header_right {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    background-color: white;
  }

  .icon_style {
    font-size: 20px;
  }

  .choose {
    background-color: #ffffff;
    width: 60%;
    height: 30px;
    font-size: 16px;
    color: white;
    position: relative;
    border-radius: 15px;
    border: 0;
    margin-left: 30%;
    margin-bottom: 2vw;
  }

  .version {
    width: 50vw;
    margin-top: -0.5vw;
    color: black;
    margin-left: -8.5vw;
  }

  .export {
    margin-left: 18px;
    margin-top: -24px;
  }

  .popup {
    left: 5%;
    width: 90%;
    margin: auto;
  }
}

@media (max-width:500px) {
  .itemsquantity{
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: column;
    align-items: baseline;
  }
  .grade {
    width: 84vw;
  }

  .version {
    margin-top: -2.5vw;
    margin-left: -11vw;
  }

  .choose {
    margin-left: 30%;
  }

  .choose_text {
    margin-left: -40%;
  }
}
</style>






