<template>
  <div class="container-fluid p-0 ">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <form class="theme-form login-form">
            <h4>Login</h4>
            <h6>Welcome back! Log in to your account.</h6>
            <!-- 邮箱 -->
            <div class="form-group">
              <label>Email Address</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-email"></i></span>
                <input v-model="email" class="form-control" type="email" required="" placeholder=""  @keyup.enter="logins"
                  :class="{ 'is-invalid': submitted && !email }" />
                <div v-show="submitted && !email" class="invalid-feedback">
                  Email is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-lock"></i></span>
                <input v-model="password" autocomplete="" class="form-control" :type="type" name="login[password]"  @keyup.enter="logins"
                  required="" placeholder="*********" :class="{ 'is-invalid': submitted && !email }" />
                <div v-show="submitted && !password" class="invalid-feedback">
                  Email is required
                </div>
                <div class="show-hide">
                  <span class="show" @click="showPassword"></span>
                </div>
              </div>
            </div>
            <div class="message" v-if="errorMessage">{{ errorMessage }}</div>
            <div class="form-group">
              <button class="btn btn-primary btn-block" type="button" @click="logins">
                  Login
                </button>
                <div class="checkbox p-0 mb-3 w-100">
                  <a class="link" href="/authentication/forgetpassword">Forgot password?</a>
                </div>
            </div>
            <p class="mt-4 mb-0">
              Don't have account?
              <router-link class="ms-2" tag="a" to="/authentication/ragister">Create Account</router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { post } from "@/model/http"
export default {
  name: 'login',
  data() {
    return {
      type: 'password',
      username: '',
      passwordjwt: '',
      errorMessage: '',
      submitted: false,
    };
  },
  created() {
    this.$store.dispatch('authentication/logout');
    const userlocalData = localStorage.getItem('users');
    this.userData = JSON.parse(userlocalData);
    // console.log(this.userData.Email);
    
    // console.log(this.formData.Email)
  },
  methods: {
    ...mapActions('login', ['login']),

    handleSubmit() {
      this.submitted = true;
      const { username, passwordjwt } = this;
      const { dispatch } = this.$store;
      if (username && passwordjwt) {
        dispatch('authentication/login', { username, passwordjwt });
      }
    },
    showPassword: function () {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    async logins() {
      try {
        // 验证邮箱格式
        if (!this.isValidEmail(this.email)) {
          this.errorMessage = 'Please enter a valid email address.';
          return;
        }
        const response = await post('/api/v1/login', { email: this.email, password: this.password });
        if (response.status) {
          const user = response.data;
          const Token = response.data.Token;
          // 调用Vuex的login action来保存登录状态
          this.login({ Token, user });

          localStorage.setItem('users', JSON.stringify(user));
          // 登录成功后跳转到其他页面
          this.$router.push({ path: '/' });
        }
        else {
          this.errorMessage = 'Invalid email or password.';
        }
      } catch (error) {
        this.errorMessage = 'Invalid email or password.';
        console.error(error);
      }
    },
    isValidEmail(email) {
      // 使用正则表达式验证邮箱格式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
  },
};
</script>
<style>
.message {
  font-weight: 500;
  color: red;
  height: 25px;
}

.login-form .btn {
  width: 100%;
}
</style>

