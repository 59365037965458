<template>
  <div>
    <div class="header">
      <h3>Shipping and Billing Preferences</h3>
      <div class="container-fluid">
        <div class="card">
          <div class="card-body row">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <label class="col-form-label">
                This information will be used as defaults during checkout and to fullfill orders created from awarded
                auction lots. For Stock List orders you will have the option of changing your selections during checkout.
              </label>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 " @click="Goback()">
              <div class="col">
                <div class="mb-6 row">
                  <div class="col-sm-1">
                    <i class="icofont icofont-rounded-left"></i>
                  </div>
                  <div class="col-sm-5 back">
                    BACK
                  </div>

                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="col shippingSelected">
                <div class="mb-6 row">
                  <label class="col-sm-12 col-form-label billingAdress">* Billing Address</label>
                  <div class="col-sm-12">
                  </div>
                  <div class="col-sm-12 inputText">
                    <input type="text" value="Unit 6, 25/F Hung to Rd No 1, Kwun Tong, HK" disabled/>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-12 col-xl-12 address-area">
              <div class="col">
                <div class="mb-12 row">
                  <label class="col-sm-12 col-form-label billingAdress">* Payment Method</label>
                  <label class="col-sm-12 col-form-label">How will you pay for this order?</label>
                  <div class="col-sm-12 ">
                    <label for="advance"  > 
                      <input type="radio" name="PaymentMethod" id="advance" v-model="shippingData.PaymentMethod" value="1"  :checked="shippingData.PaymentMethod === 'Wire in Advance'"/>Wire in Advance</label>
                  </div>
                  <div class="col-sm-12">
                    <label for="ach">
                      <input type="radio" name="PaymentMethod" v-model="shippingData.PaymentMethod" id="ach" value="2" :checked="shippingData.PaymentMethod === 'ACH'"/>ACH</label>
                  </div>
                </div>
              </div>

              <div class="sub_ok">
                <button type="submit" @click="submitPayment">OK</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {axiosShipping,axiosEditShipping } from "@/api/Shipping"
export default {
  created() {
    this.syncData();
  },
  mounted() {
    if (!this.shippingData.PaymentMethod) {
      this.shippingData.PaymentMethod = 'Wire in Advance';
    }
  },
  data() {
    return{
      shippingData:{
        PaymentMethod: '',
      }
    }
  },
  methods: {
    Goback() {
      this.$router.go(-1);
    },
    syncData() {
      axiosShipping().then((result) => {
        this.shippingData.PaymentMethod  = result.data.PaymentMethod ;
      });
      
    },
    submitPayment(){
      let itemdata = {"PaymentMethod":this.shippingData.PaymentMethod};
      axiosEditShipping(itemdata).then((result) => {
        if(result.status === true){
          this.$router.push('/preference/review');
        }
       
      });
    }

  },
 

}
</script>
<style scoped>
.header {
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 12px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.header-note {
  font-size: 17px;
  line-height: 25px;
}

.address-area {
  margin-top: 50px;
}

.EditShipping {
  color: #0070b9;
  font-size: 16px;
  font-weight: 600;
  margin-left: 12px;
}

i {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  color: #0070b9;
}

.back {
  font-size: 18px;
  font-weight: 500;
  margin-left: -4%;
  color: #0070b9;
}

.hint {
  font-size: 13px !important;
}

.inputText {
  position: relative;
  display: inline-block;
}

.inputText input {
  width: 90%;
  height: 3vw;
  font-size: 16px;
  font-weight: 500;
  margin-top: 14px;
  padding: 15px;
  border: 1px solid #ccc;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.inputText input:focus {
  border-color: #383839;
  box-shadow: 0 0 8px rgba(44, 44, 45, 0.6);
  outline: none;
}

.shippingSelected {
  margin-top: 22px;
}

.billingAdress {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.sub_ok {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}

.sub_ok button {
  width: 14%;
  font-weight: 500;
  height: 3vw;
  font-size: 16px;
  border: 0;
  background-color: #0070b9;
  color: white;
  border-radius: 6px;
}

@media(max-width:850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }
  ..inputText input{
    height:4vw;
  }
  .sub_ok button{
    height:4vw;
  }
}
</style>