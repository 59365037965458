import {get,patch} from "@/model/http"

export function axiosGetAllOrders(data) {
    return get('/api/v1/orders/list',data);
}

export function axiosGetOrderDetail(id) {
    return get('/api/v1/orders/'+id);
}

export function axiosCheckout(id) {
    return patch('/api/v1/orders/'+id);
}