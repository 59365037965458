<template>
  <div v-if="isActive">
    <div>
      <div class="lineheigth">
        <div class="headlines" style="text-align:left">
          <div class="artitleseed"> Items</div>
          <div class="artitler">Qty</div>
          <div class="artitled">Price</div>
          <div class="artitlebtn"></div>
        </div>
        <div class="body_title" v-for="(k, iteminfo) in job.items" style="text-align:left">
          <div class="artitle">
            <div class="artitles">
              <div class="description">
                {{ k.Description ? k.Description : '*' }}
                <nobr class="model-number" @mouseover="showTooltip(k)" @mouseleave="hideTooltip">
                  {{ k.attributes.modelNumber }}</nobr>
                <div v-if="k === tooltipVisible" id="tooltip">
                  model: {{ k.attributes.modelNumber }}-item#: {{ k.itemNumber }}
                </div>
              </div>

            </div>
            <div class="artitlerls">
              <div>{{ k.quantity }}</div>
            </div>
            <div class="artitlebtnls">
              <div>{{ k.Price }}</div>
            </div>
          </div>

          <div class="artitlebtn">
            <div class="btn_cart" v-if="!k.isOffer">
              <button class="btn btn-square btn-outline-success btn_style" @click="showBuys(k)">buy</button>
              <button class="btn btn-square btn-info btn_style" @click="showPopups(k)">offer</button>
            </div>
            <div v-else-if="k.isOffer">
              <i @click="showOfferUpdate(k)" class="btn in_cart">{{ k.offer.OfferQuantity }} IN Cart</i>
            </div>
            <div>
              <i v-if="k.hasAlert" class="icofont icofont-bell-alt i_bell bell_btn" @click="showAlerts(k)"></i>
              <i v-else class="icofont icofont-bell-alt i_bell bell_btn no_bell " @click="showAlerts(k)"></i>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="whole">
      <button class="btn btn-link ps-0" data-bs-toggle="collapse" data-bs-target="#collapseicon"
        :aria-expanded="isActive ? 'true' : 'false'" aria-controls="collapseicon"
        :class="[isActive ? 'active' : '', 'collapsible']" @click="toggle">
        <a href="javascript:void(0)"><i class="icofont icofont-curved-up"
            style="color: black;font-size: 24px;margin-left: 4.5vw;"></i></a>
      </button>
    </div>
  </div>
  <div class="whole" v-if="!isActive">
    <button class="btn btn-link ps-0" data-bs-toggle="collapse" data-bs-target="#collapseicon"
      :aria-expanded="isActive ? 'true' : 'false'" aria-controls="collapseicon"
      :class="[isActive ? 'active' : '', 'collapsible']" @click="toggle">
      <a href="javascript:void(0)"><i class="icofont icofont-curved-down"
          style="color: black;font-size: 24px; margin-left: 4vw;"></i></a>
    </button>
  </div>

  <!-- offer编辑 -->
  <div v-if="showUpdateOffer">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">{{ offer.FullDescription }}</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>
        <div class="popup_item">ltem #{{ offer.itemNumber }}</div>
        <div class="popup_middle">
          <div class="popup_first">
            <div>
              <span>Avail: </span>
              <span class="old_style">{{ offer.quantity }}</span>
            </div>
            <div>
              <span class="old_price">Price: </span>
              <span class="old_style">${{ offer.Price }}</span>
            </div>
          </div>
          <div class="selector">
            <div class="selector_all">
              <span class="new_qty">Qty:</span>
              <input type="number" :min="1" :max="offer.quantity" v-model="offer.offerQuantity" v-input-limit="5" />
            </div>
            <div class="selector_all">
              <span class="new_price">Price:</span>
              <input type="number" step="1" :min="1" :max="offer.Price" v-model="offer.offerPrice" v-input-limit="5" />
            </div>
            <div class="selector_button">
              <button class="add_cart" @click="updateOffer()">UPDATE CART</button>
              <a class="text-warning" @click="removeOffer()">REMOVE FROM CART</a>
            </div>
          </div>
          <div v-if="errorInfo" class="errormessage">{{ errorInfo }}</div>
        </div>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
  <!-- offer小弹窗 -->
  <div v-if="showPopup">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">{{ offer.FullDescription }}</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>

        <div class="popup_item">ltem #{{ offer.itemNumber }}</div>
      </div>
      <div class="popup_middle">
        <div class="popup_first">
          <div>
            <span>Avail: </span>
            <span class="old_style">{{ offer.quantity }}</span>
          </div>
          <div>
            <span class="old_price">Price: </span>
            <span class="old_style">${{ offer.Price }}</span>
          </div>
        </div>
        <div class="selector">
          <div class="selector_all">
            <span class="new_qty">Qty:</span>
            <input type="number" :min="1" :max="offer.quantity" v-model="offer.offerQuantity" v-input-limit="5" />
          </div>
          <div class="selector_all">
            <span class="new_price">Price:</span>
            <input type="number" step="1" :min="1" :max="offer.Price" v-model="offer.offerPrice" v-input-limit="5" />
          </div>

          <button class="add_cart" @click="addOffer()">ADD TO CART</button>
        </div>
        <div class="switch_alert_btn">
          <i class="icofont icofont-bell-alt i_bells"></i>
          <a @click="showAlerts(offer)">ALERT ME WHEN MORE ARE AVAILABLE</a>
        </div>
        <div v-if="errorInfo" class="errormessage">{{ errorInfo }}</div>
        <div v-if="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
  <!-- Alert编辑 -->
  <div v-if="showUpdated">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">{{ alert.FullDescription }}</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>
        <div class="popup_first">
          <div>
            <span>Avail: </span>
            <span class="old_style">{{ alert.quantity }}</span>
          </div>
          <div>
            <span class="old_price">Price: </span>
            <span class="old_style">$ {{ alert.Price }}</span>
          </div>
        </div>
      </div>
      <div class="selector_all">
        <div>
          <div style="display:flex">
            <i class="icofont icofont-bell-alt i_bells"></i>
            <span>Alert me when you add
              <input type="number" :min="1" step="1" v-model="alert.alertQuantity" v-input-limit="5" />
              units and price:
              <input type="number" :min="1" step="1" v-model="alert.alertPrice" v-input-limit="5" /></span>
          </div>
        </div>
      </div>
      <div class="alert_btn">
        <a class="text-warning alert-rm-btn" @click="removeAlerts()">REMOVE</a>
        <button class="add_carts" @click="updateAlert()">UPDATE</button>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
  <!-- buy小弹窗 -->
  <div v-if="showBuy">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">{{ buy.FullDescription }}</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>
        <div class="popup_item">ltem #{{ buy.itemNumber }}</div>
      </div>
      <div class="popup_middle">
        <div class="popup_first">
          <div>
            <span>Avail: </span>
            <span class="old_style">{{ buy.quantity }}</span>
          </div>
          <div>
            <span class="old_price">Price: </span>
            <span class="old_style">${{ buy.Price }}</span>
          </div>
          <div v-if="!buy.isOffer" class="make_or">
            <span>OR</span>
            <a @click="addOffer()"> Make an Offer</a>
          </div>
        </div>
        <div class="selector">
          <div class="selector_all">
            <span class="new_qty">Qty:</span>
            <input type="number" :min="1" :max="buy.buyQuantity" v-model="buy.buyQuantity" v-input-limit="5" />
          </div>
          <button class="add_cart" @click="buyProduct()">ADD TO CART</button>
        </div>
        <div class="switch_alert_btn">
          <i class="icofont icofont-bell-alt i_bells"></i>
          <a @click="showAlerts(buy)">ALERT ME WHEN MORE ARE AVAILABLE</a>
        </div>
        <div v-if="errorInfo" class="errormessage">{{ errorInfo }}</div>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
  <!-- alert小弹窗 -->
  <div v-if="showAlert">
    <div class="popup">
      <div class="popup_top">
        <div class="popup_header">
          <div class="popup_title">{{ alert.FullDescription }}</div>
          <i class="icofont icofont-error close-card font-primary" @click="hidePopup"></i>
        </div>
        <div class="popup_first">
          <div>
            <span>Avail: </span>
            <span class="old_style">{{ alert.quantity }}</span>
          </div>
          <div>
            <span class="old_price">Price: </span>
            <span class="old_style">$ {{ alert.Price }}</span>
          </div>
        </div>
      </div>
      <div class="selector_all">
        <div>
          <div style="display:flex">
            <i class="icofont icofont-bell-alt i_bells no_bell"></i>
            <span>Alert me when you add
              <input type="number" :min="1" step="1" v-model="alert.alertQuantity" v-input-limit="5" />
              units and price:
              <input type="number" :min="1" step="1" v-model="alert.alertPrice" v-input-limit="5" /></span>
          </div>
        </div>
      </div>
      <div class="alert_btn">
        <button class="add_carts" @click="addAlert()">SET ALERT</button>
      </div>
    </div>
    <div class="popup-cover"></div>
  </div>
</template>
<script>
import { mapState, useStore, mapActions } from 'vuex';
import { axiosAddAlert, axiosUpdateAlert, axiosDelAlert } from "@/api/alert";
import { axiosUpdateCart, axiosDelCart } from "@/api/cart";
export default {
  props: ['job'],
  data() {
    return {
      params: {
        page: null,//当前页
        pagesize: null,//每一页需要显示的条数
        name: '',
        includOutStock: null,
        selectedFilters: null,
      },
      offer: null,
      alert: null,
      buy: null,
      isActive: false,
      cart: [],
      active: true,
      showPopup: false,
      showBuy: false,
      showAlert: false,
      showUpdates: false,
      showAlertss: false,
      showUpdated: false,
      showUpdateOffer: false,//offer编辑
      errorInfo: '',
      tooltipVisible: null
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('jobs', ['getAllJob']),
    toggle() {
      this.isActive = !this.isActive;
    },
    showPopups(item) {
      this.offer = item;
      this.offer.offerQuantity = item.quantity;
      this.offer.offerPrice = item.Price;
      this.showPopup = !this.showPopup;
    },
    showBuys(item) {
      this.buy = item;
      this.buy.buyQuantity = item.quantity;
      this.buy.buyPrice = item.Price;
      this.showBuy = !this.showBuy;
    },
    hidePopup() {
      this.showPopup = false;
      this.showBuy = false;
      this.showAlert = false;
      this.showUpdates = false;
      this.showAlertss = false;
      this.showUpdateOffer = false;
      this.showUpdated = false;
      this.errorInfo = ''
    },
    showAlerts(item) {
      this.hidePopup();
      if (!item.hasAlert) {
        this.alert = item;
        this.alert.alertQuantity = item.quantity;
        this.alert.alertPrice = item.Price;
        this.showAlert = !this.showAlert;
      } else {
        this.alert = item;
        this.alert.alertQuantity = item.alert.Quantity;
        this.alert.alertPrice = item.alert.Price;
        this.showUpdated = true;
      }
    },
    showUpdate() {
      this.showUpdates = true;
    },
    showOfferUpdate(item) {
      this.offer = item;
      this.offer.offerQuantity = item.offer.OfferQuantity;
      this.offer.offerPrice = item.offer.OfferPrice;
      this.showUpdateOffer = true;
    },
    buyProduct() {
      let item = [{ "id": this.buy.itemNumber, "quantityRequested": this.buy.buyQuantity, "isOffer": false }];
      axiosUpdateCart(item).then(rep => {
        if (rep.status === true) {
          this.hidePopup();
          this.getAllJob(this.params);
        } else {
          this.errorInfo = rep.message;
        }

      });
    },
    //offer添加
    addOffer() {
      let item = [{ "id": this.offer.itemNumber, "offerQuantity": this.offer.offerQuantity, "isOffer": true, "offerPrice": this.offer.offerPrice }]
      axiosUpdateCart(item).then(rep => {
        if (rep.status === true) {
          this.hidePopup();
          this.getAllJob(this.params);
        } else {
          this.errorInfo = rep.message;
        }
      });
    },
    //offer中的编辑
    updateOffer() {
      let item = [{ "id": this.offer.itemNumber, "offerQuantity": this.offer.offerQuantity, "isOffer": true, "offerPrice": this.offer.offerPrice }]
      axiosUpdateCart(item).then(rep => {
        if (rep.status === true) {
          this.hidePopup();
          this.getAllJob(this.params);
        } else {
          this.errorInfo = rep.message;
        }

      });
    },
    //删除offer
    removeOffer(iteminfo, cartid) {
      let item = [{ "id": this.offer.itemNumber }]
      axiosDelCart(item).then(rep => {
        this.hidePopup();
        this.getAllJob(this.params);
      });
    },
    //Alert编辑
    addAlert() {
      let item = { "id": this.alert.itemNumber, "quantity": this.alert.alertQuantity, "price": this.alert.alertPrice }
      axiosAddAlert(item).then(rep => {
        this.hidePopup();
        this.getAllJob(this.params);
      });
    },
    //Alert编辑
    updateAlert() {
      let item = { "id": this.alert.itemNumber, "quantity": this.alert.alertQuantity, "price": this.alert.alertPrice }
      axiosUpdateAlert(item).then(rep => {
        this.hidePopup();
        this.getAllJob(this.params);
      });
    },
    //alert删除方法
    removeAlerts(ItemID) {
      let item = { "id": this.alert.itemNumber }
      axiosDelAlert(item).then(rep => {
        this.hidePopup();
        this.getAllJob(this.params);
      });
    },
    showTooltip(k) {
      this.tooltipVisible = k;
    },
    hideTooltip() {
      this.tooltipVisible = null;
    }
  },
  directives: {
    'input-limit': {
      mounted(el, binding) {
        el.addEventListener('input', (event) => {
          const maxLength = binding.value;
          if (event.target.value.length > maxLength) {
            event.target.value = event.target.value.slice(0, maxLength);
            event.target.dispatchEvent(new Event('input'));
          }
        });

      }
    }
  },
}
</script>
<style scoped>
.fa {
  font-size: 5vw;
  color: blue;
}



.errormessage {
  font-size: 12%;
  color: red;
  font-weight: 500;
}

.whole {
  width: 100% !important;
  text-align: center;
}


.grade {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 8px;
  width: 45vw;
}

.lineheigth {
  display: flex;
  flex-direction: column;
}

.del {
  background-color: #0070b9;
  border: 0;
  margin-left: 8vw;
  width: 80px;
  padding: 7px;
  border-radius: 17px;
  font-size: 13px;
  color: white;
}

.headline {
  display: flex;
  justify-content: space-evenly;
}

.headlines {
  display: flex;
}

.body_title {
  display: flex;
}

.items,
.qty,
.price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 7vw;
  font-size: 20px;
}

.btn_cart {
  display: flex;
}

.subtitles1 {
  font-size: 6px;
}

.subtitles {
  font-size: 20px;
}

.whole {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 37vw;
}

.headlines .artitler,
.headlines .artitlesed,
.headlines .artitle,
.headlines .artitled,
.headlines .artitleseed {
  color: #000000;
  font-weight: 500;
}

.artitleseed {
  width: 33%;
}

.artitler,
.artitles,
.artitle,
.artitlebtnls,
.artitleseed,
.artitlerls,
.artitled {
  font-size: 14px;
  color: #739199;
  margin-bottom: 8px;
}

.artitlebtnls {
  width: 13%;
  margin-left: 7%;
  display: flex;
  align-items: center;
}

.artitlerls {
  width: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.artitle {
  width: 85%;
  display: flex;
}

.artitles {
  width: 80%;
}


.artitler {
  margin-left: 16%;
  width: 8%;
  text-align: center;
}

.artitled {
  margin-left: 4%;
  width: 12%;
  text-align: center;
}

.artitlebtn {
  width: 70%;
  margin-top: -5px;
  display: flex;
  height: 25px;
  justify-content: space-evenly;
  text-align: right;
}

.in_cart {
  background-color: #3898ab;
  height: 24px;
  text-align: center;
  border-radius: 13px;
  color: #fff;
  padding: 2px;
  margin-right: 19px;
  width: 165px;
}

.artitlevalue {
  font-size: 20px;
  font-weight: 500;
  color: #3a494d;
}

.btn_style {
  border-radius: 20px;
  margin-right: 20px;
  padding: 0px 20px;
}

.make_or {
  margin-left: 12px;
}

.i_bell {
  font-size: 25px;
  color: #3898ab;
  height: 25px;
  border-radius: 10px;
  margin: 34px -18px 0px 0px;
}

.back {
  width: 200vw;
  height: 20000vw;
  background-color: #666666;
  margin-top: -81vw;
  margin-bottom: 100vw;
}

.popup {
  position: fixed;
  top: 20%;
  left: 30%;
  width: 40%;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 101;
  margin: 30px auto;
}

.btn_cancel {
  font-size: 6px;
  border: 0;
  width: 7vw;
  height: 4vw;
  margin-top: -1vw;
  margin-left: 16vw;
}

.popup_header {
  display: flex;
  justify-content: space-between;
}

.popup_title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 90%;
}

.popup_item {
  color: #3a494d;
  margin-top: 2px;
  margin-bottom: 10px;
}

.popup_first {
  display: flex;
  margin-bottom: 12px;
}

.popup-cover {
  background: #000000;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.middle_title {
  font-size: 16px;
  font-weight: 500;
  color: #3a494d;
  margin-bottom: 5px;
}

.old_price {
  margin-left: 5vw;
}

#old_style {
  margin-left: 2vw;
}

.selector {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.selector_all {
  display: flex;
}

.selector_button {
  text-align: center;
}

.selector_all input {
  width: 60px;
  height: 30px;
  margin: 0 10px;
  text-align: center;
}

.add_cart {
  color: white;
  background-color: #0070b9;
  border: 0;
  width: 180px;
  padding: 7px;
  border-radius: 17px;
  font-size: 13px;
  display: block;
}

.alert_btn {
  float: right;
}

.add_carts {
  width: 120px;
  padding: 7px;
  border-radius: 17px;
  font-size: 13px;
  margin-top: 12px;
  color: white;
  background-color: #0070b9;
  border: 0;
}

.new_qty,
.new_price {
  line-height: 34px;
}


.i_bells {
  font-size: 25px;
  color: black;
  margin: 0 !important;
}

.close-card,
.bell_btn {
  cursor: pointer;
}

.no_bell {
  color: #7b7b7b;
}


.model-number {
  margin-left: 5px;
  font-size: 12px;
}

.model-number:hover {
  cursor: pointer;
}

.text-warning,
.switch_alert_btn {
  cursor: pointer;
}

.alert-rm-btn {
  margin-right: 10px;
}

#tooltip {
  position: absolute;
  background-color: #f0f0f0;
  padding: 5px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

}

@media(max-width:850px) {
  .popup {
    left: 5%;
    width: 90%;
    margin: auto;
  }
}

@media(max-width:700px) {
  .artitle {
    display: flex;
    width: 74%;
    justify-content: center;
  }

  .body_title {
    display: flex;
    flex-direction: column;
    height: 17vw !important;
  }

  .artitlerls {
    margin-left: 13%;
  }


  .lineheigth {
    margin-left: 18%;
  }

  .artitleseed {
    width: 80vw;
  }

  .headlines .artitler,
  .headlines .artitlesed,
  .headlines .artitle,
  .headlines .artitled {
    width: 55% !important;
    color: #000000;
    font-weight: 500;
  }

  .artitles,
  .artitlerls,
  .artitlebtnls {
    width: 33%;
  }

  .artitles {
    width: 100vw !important;
  }

  .description {
    width: 26vw !important;
  }

  .artitlerls {
    margin-left: 8%;
  }

  .artitler {
    margin-left: 1% !important;
  }

  .artitled {
    margin-left: 9% !important;
  }


  .artitlebtnls {
    margin-left: 32%;
  }

  .add_cart {
    font-size: 10px;
    width: 106px;
  }

  .text-warning {
    font-size: 10px;
  }


}

@media(max-width:540px) {

  .body_title {
    height: 27vw !important;
  }

  .artitlerls {
    margin-left: 4%;
  }


  .artitlebtnls {
    margin-left: 25%;
  }

  .btn_style {
    margin-right: 25px;
  }

  .in_cart {
    margin-right: 23px;
    width: 171px;
  }
}
</style>