<template>
  <div>
    <div>
      <div class="header">
        <h3>Orders</h3>
      </div>
      <div class="container-fluid">
        <div class="card">
          <div class="card-block row">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="table-responsive" style="padding:10px;">
                <div id="basic-9_wrapper" class="dataTables_wrapper">
                  <div id="basic-9_filter" class="dataTables_filter">
                    <label>Search:
                      <input type="search" v-model="search" placeholder="" aria-controls="basic-9"
                        data-bs-original-title="" title="">
                    </label>
                  </div>
                </div>

                <table class="table">
                  <thead class="table-success">
                    <tr class="table-row ">
                      <th scope="col">Date</th>
                      <th scope="col">Order #</th>
                      <th scope="col">Warehouse</th>
                      <th scope="col" class="noneMB">Type</th>
                      <th scope="col" class="noneMB">Status</th>
                      <th scope="col" class="noneMB">Quantity</th>
                      <th scope="col" class="noneMB">Currency</th>
                      <th scope="col" class="noneMB">Total</th>
                    </tr>
                  </thead>
                  <tbody v-if="orderslist.length > 0">
                    <tr v-for="(order, index) in orderslist" :key="index" class="table-row ">
                      <td>{{ order.OrderDate }}</td>
                      <td>{{ order.OrderNumber }}</td>
                      <td><router-link :to="{ name: 'orderdetail', params: { id: order.ID } }">{{ order.warehouse
                      }}</router-link></td>
                      <td class="noneMB">STOCKLIST</td>
                      <td class="noneMB">{{ order.Status }}</td>
                      <td class="noneMB">{{ order.QuantityText }}</td>
                      <td class="noneMB">{{ order.Currency }}</td>
                      <td class="noneMB">{{ order.TotalText }}</td>
                      <br/>
                      
                      <!-- <td>
                        <hr style="width: 100%;
                      margin-left: -51vw;
                      color: #e6edef;margin-top: 7vw;"/>
                        <tr >
                          <th>Status</th>
                          <td>{{ order.Status }}</td>
                          <br/><br/>
                          <th>Quantity</th>
                          <td>{{ order.QuantityText }}</td></tr>
                      </td> -->
                      
                    </tr>
                    <!-- <tr v-for="(order, index) in orderslist" :key="index" class="showContent">
                      <th>Status</th>
                      <td>{{ order.Status }}</td>
                      <br/>
                      <th>Quantity</th>
                      <td>{{ order.QuantityText }}</td>
                    </tr> -->
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8">
                        <div class="Nodata">No data present</div>
                      </td>

                    </tr>

                  </tbody>
                </table>


                <!-- 分页 -->
                <div v-if="orderslist.length > 0" class="job-pagination">
                  <nav aria-label="Page navigation example">
                    <div class="nav_left" v-if="orderslist">
                      <div>Showing <span>{{ (params.page - 1) * pageSize + 1 }}</span>to<span>{{ totalRecodes > pageSize
                        && params.page
                        *
                        pageSize < totalRecodes ? params.page * pageSize : totalRecodes }}</span>of<span>{{ totalRecodes
  }}</span>results
                      </div>
                    </div>
                    <ul class="pagination pagination-primary">
                      <li class="page-item" @click="prePage()" :class="{ 'disabled': params.page == 1 }">
                        <a class="page-link" style="cursor: pointer;">Previous</a>
                      </li>
                      <li :class="[params.page == page ? 'page-item active' : 'page-item']" v-for="(page, index) in pages"
                        :key="index" @click="curPage(page)">
                        <a class="page-link" style="cursor: pointer;">{{ page }}</a>
                      </li>
                      <li class="page-item" :class="{ 'disabled': params.page == totalPage }" @click="next()">
                        <a class="page-link" style="cursor: pointer;">Next</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div v-else></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosGetAllOrders } from "@/api/order"
export default {
  created() {
    this.syncData();
  },
  data() {
    return {
      params: {
        page: 1,//当前页
        pagesize: 10,//每一页需要显示的条数
        search: null,
        sort: null,
      },
      orderslist: [],
      drawpage: 1,
      totalRecodes: 0,
      pageSize: 10,
      search: '',
    };
  },
  computed: {
    pages() {
      let totalPage = Math.ceil(this.totalRecodes / this.pageSize); //总记录数/每页显示数 = 总页数
      this.totalPage = totalPage;
      this.params.page = this.drawpage;
      let page = this.params.page;
      if (totalPage < 10) return totalPage;
      if (page <= 2) {
        return [1, 2, 3, '...', totalPage]
      } else if (page >= totalPage - 3) {
        return [1, '...', totalPage - 1, totalPage]
      } else { // 中间五页
        return [1, '...', page - 1, page, page + 1, '...', totalPage]
      }
    },

  },
  watch: {
    search() {
      this.params.search = this.search;
      this.syncData(this.params);
    }
  },
  methods: {
    syncData() {
      axiosGetAllOrders(this.params).then((result) => {
        this.orderslist = result.data;
        // console.log(this.orderslist);
        this.drawpage = result.draw;
        this.totalRecodes = result.recordsTotal;
        this.pageSize = result.pageSize;
      });
    },
    //中间
    curPage(page) {
      if (page == '...') return
      if (this.flag) return;
      this.params.page = page;
      this.syncData(this.params);
    },
    //上一页
    prePage() {
      if (this.params.page > 1) {
        if (this.flag) return;
        --this.params.page;
        this.syncData(this.params);
      }
    },
    //下一页
    next() {
      if (this.flag) return;
      // console.log("执行", this.totalPage)
      if (this.params.page == this.totalPage) {
        return false
      } else {
        ++this.params.page;
        this.syncData(this.params)
      }
    },
  },
}
</script>
<style scoped>
.header {
  justify-content: space-between;
  margin-bottom: 5px;
}

h3 {
  margin-left: 15px;
  margin-bottom: 12px !important;
}

.Nodata {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
}

.table {
  margin-bottom: 10px;
}

.nav_left {
  font-size: 12px;
  margin: 0 0 15px 10px;
}

.nav_left span {
  margin: 0 10px;
}

.job-pagination {
  margin: 40px 0;
}

.pagination-primary .page-item.active .page-link {
  background-color: #3898ab !important;
}

.job-pagination nav ul {
  justify-content: right;
}

.showContent {
  display: none;
}

@media(max-width:850px) {
  .header {
    flex-direction: column;
    min-width: 100%;
    text-align: left;
  }

  tr.child {
    display: table-row;
  }

}

.job-pagination nav ul {
  justify-content: right;
}

@media(max-width:540px) {
  #basic-9_filter input {
    margin-bottom: 5vw;
  }

  .noneMB {
    display: none;
  }

  .showContent {
    display: block;
  }
}
</style>