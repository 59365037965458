import { axiosGetProducts } from "@/api/product";

const state = {
	jobsData: null,
	jobsFilter: null,
	itemQuantityFormatted:'',
	quantityTotalFormatted:'',
	offers: 0,
	lastUpdated:'',
	draw:1,
	totalRecords: 0,
	selectedFilters: '',
	pageSize: 10,
	isLoading: false,
	category: null,
	showPopup: false,
	isRequestSuccessful: false,
	showBuy: false,
	showAlert: false,
	showImport:false,
	includOutStock:false,
	page: 1,
}
const actions = {
	getAllJob: async ({ dispatch, commit }, payload) => {
		//const { page, pagesize, category } = payload;
		const page = payload.page != null ? payload.page : state.page;
		const pageSize = payload.pagesize != null ? payload.pagesize : state.pageSize;
		const selectedFilters = payload.selectedFilters != null ? payload.selectedFilters : state.selectedFilters;
		const includOutStock = payload.includOutStock != null ? payload.includOutStock : state.includOutStock;

		commit('setLoading', true)
		try {
			let data = {
				page: page,
				pageSize: pageSize,
			};
			for (let key in selectedFilters) {
				if (Array.isArray(selectedFilters[key])) {
					selectedFilters[key].forEach(value => {
						if(data[key] == undefined) {
							data[key] = [];
						}
						data[key].push(encodeURIComponent(value));
					});
				}
			}
			if(includOutStock) {
				data.includOutStock = 1;
			}
			axiosGetProducts(data).then(products => {
				// 提交mutation来更新state
				commit('setLoading', false);
				// console.log('fetch',jobs)
				commit('getJobData', products);
				commit('togglePopup', true);
				commit('hidePopup');
				commit('toggleBuy', true);
				commit('toggleAlert', true);
			});;
		}
		catch (error) {
			// 处理错误
			console.error(error);
			commit('setLoading', false);
		}
	},
}


const mutations = {
	getJobData: (state, jobs) => {
		state.draw = jobs.draw;//当前页码
		state.jobsData = jobs.data;
		state.jobsFilter = jobs.filter;
		state.totalRecords = jobs.recordsTotal; //总记录数
		state.pageSize = jobs.pageSize;
		state.selectedFilters = jobs.selectedFilters;
		state.page = jobs.draw;
		state.includOutStock = jobs.includOutStock;
		state.offers = jobs.offers;
		state.lastUpdated = jobs.lastUpdated;
		state.itemQuantityFormatted=jobs.itemQuantityFormatted;
		state.quantityTotalFormatted=jobs.quantityTotalFormatted;
	},
	setLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	togglePopup(state) {
		state.showPopup = !state.showPopup;
	},
	hidePopup(state) {
		state.hidePopup = false
	},
	setRequestStatus(state, status) {
		state.isRequestSuccessful = status
	},
	toggleBuy(state) {
		state.showBuy = !state.showBuy;
	},
	toggleAlert(state) {
		state.showAlert = !state.showAlert;
	},
	toggleImport(state) {
		state.showImport = !state.showImport;
	},

}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}
