
const state = {
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem("user") ,
    //? JSON.parse(localStorage.getItem("user")) : null,
    isLoggedIn: false

}
const actions = {
    login({ commit }, { Token, user }) {
        // console.log('login',user)
        // console.log('token',Token)
        commit('SET_TOKEN', Token);
        commit('SET_USER', user);
        commit('SET_LOGGED_IN', true);
    },
    setToken({ commit }, token) {
        commit('SET_TOKEN', token);
      },
    logout({ commit }) {
        commit('SET_TOKEN', '');
        commit('SET_USER', {});
        commit('SET_LOGGED_IN', false);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }
}


const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
        localStorage.setItem('token', token);
    },
    SET_USER(state, user) {
        state.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    },
    SET_LOGGED_IN(state, value) {
        state.isLoggedIn = value;
    }

}

const getters = {
    isLoggedIn: state => !!state.token
  }

export default {
    namespaced: true,
    state,
    mutations,
    actions
}