<template>
  <h2>Current path not found</h2>
</template>
<style scoped>
h2{
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 500;
  margin-top:45%;
}
</style>