import axios from "axios";
const state = {
	cartData:[],
	cartItem:null,
	isLoading: false,
	items: [],
}

const actions = {
	getAllCart: async ({commit,state} ,item) => {
		// console.log('item',item);
		commit('setLoading', true)
		
		// const cartItem = [item.quantity, item.price];
		// console.log(cartItem);
		fetch('/api/v1/cart', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(item)
		  })
			.then(response => response.json())
			.then(
				data =>{
					// console.log(data),
					commit('getCartData',data)
					commit('setLoading', false);
				})
			.catch(error => console.error(error));
	},
	//获取购物车里的数据
	getCartlist:({commit})=>{
		commit('setLoading', true)
		axios.get('/v1/cart', {
			headers: {
			  'Content-Type': 'application/json'
			}
		  })
		  .then((response) => {
			// console.log(response.data);
			// 请求成功处理
			let data=response.data;
			commit('getCartData',data)
			commit('setLoading', false);
		  })
		.catch((error) => {
			// 请求错误处理
			console.error(error);
		  });
	},
	//删除cart
	deleteItem({ commit }, cartId) {g
		axios.delete(`/v1/cart`,{data:cartId})
		  .then((response) => {
			// commit('removeItem', itemId);
		  })
		  .catch((error) => {
			console.error('Error deleting item:', error);
		  });
	}
}

const mutations = {
	getCartData: (state, item) => {
		state.cartData = item;
		// console.log(state.cartData);
		state.cartItem=item.data;
	},
	setLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	removeItem(state, itemId) {
		console.log(state,itemId);
		state.items = state.items.filter((item) => item.offerID !== itemId);
	  },

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}