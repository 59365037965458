import { createStore } from 'vuex'
import layout from './modules/layout';
import menu from './modules/menu'
import chartData from "./modules/chart"
import common from './modules/common';
import products from './modules/products'
import email from "./modules/email"
import chat from "./modules/chat"
import todo from "./modules/todo"
import firebase_todo from "./modules/firebase_todo"
import table from './modules/table';
import icon from "./modules/icon"
import jobs from "./modules/jobs"
import login from "./modules/login"
import cart from "./modules/cart"
import submit from "./modules/submit"
import courses from "./modules/courses"
import support from "./modules/support"
import masonry from "./modules/masonry"
import { authentication } from './modules/authentication';
import { users } from './modules/users';
import filemaneger from "./modules/filemaneger"
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authentication,
    layout,
    menu,
    chartData,
    common,
    products,
    email,
    chat,
    todo,
    firebase_todo,
    table,
    icon,
    jobs,
    login,
    cart,
    submit,
    courses,
    support,
    users,
    masonry,
    filemaneger
  }
})
