import {get,post} from "@/model/http"

export function axiosGetProducts(data) {
    return get('/api/v1/products/list',data);
}

export function axiosGetImportProducts(data) {
    return post('/api/v1/products/import-offers',data);
}

export function axiosSubmitOffer() {
    return post('/api/v1/products/submit-offers');
}