<template>
<div class="page-body-wrapper">
  <div class="page-body">
    <div class="card-form">
      <div class="card">
        <div class="card-header">
          <h5>Your application has been submitted, please wait for approval....</h5>
        </div>
        <div class="card-body">
          <div class="clipboaard-container">
            <div class="shenhe_login">
              <RouterLink to="/auth/login"><button class="btn btn-primary btn-block" type="button"> Login </button></RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.page-body-wrapper {
  margin: 0 auto;
}

.card-form {
  background-color: rgba(36, 105, 92, 0.1);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 12px;
}

.card {
  padding: 30px;
  width: 450px;
  background-color: #fff;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.shenhe_login {
  text-align: center;
}
</style>