<template>
  <div class="col-xl-8">
    <form class="card">
      <div class="card-header pb-0">
        <h4 class="card-title mb-0">Edit Profile</h4>
        <div class="card-options"><a class="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i
              class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#" data-bs-toggle="card-remove"><i
              class="fe fe-x"></i></a></div>
      </div>
      <div class="card-body">
        <div class="theme-form register-form">
          <div class="first title">
            <h5>Business Contact Information</h5>
            <!-- CompanyLegalName -->
            <div class="mb-3">
              <label for="formCompanyLegalNameInput" class="form-label">Company Legal Name*</label>
              <input type="text" v-model="formData.CompanyLegalName" class="form-control" id="formCompanyLegalNameInput"
                required>
              <div class="Prompt" v-if="!formData.CompanyLegalName && showCountryPrompt">Company Legal Name is required
              </div>
            </div>
            <!-- FullLegalNameCompany -->
            <div class="mb-3">
              <label for="formCompanyRegistrationInput" class="form-label">EIN/BR/Company Registration*</label>
              <input type="text" v-model="formData.CompanyRegistration" class="form-control"
                id="formCompanyRegistrationInput" required>
              <div class="Prompt" v-show="!formData.CompanyRegistration && showCountryPrompt">EIN/BR/Company
                Registration
                is required</div>
            </div>
            <!-- RegisteredCompanyAddress -->
            <div class="mb-3">
              <label for="formRegisteredCompanyAddressInput" class="form-label">Registered Company Address(Shipping
                Address)*</label>
              <input type="text" v-model="formData.RegisteredCompanyAddress" class="form-control font"
                id="formRegisteredCompanyAddressInput" required>
              <span class="tips">Please complete this field only if you have a registered fictitious/trade name and
                attach
                proof</span>
              <div class="Prompt" v-show="!formData.RegisteredCompanyAddress && showCountryPrompt">Registered Company
                Address is required</div>
            </div>
            <div class="ownership_three">
              <!-- BusinessContactCity -->
              <div class="citys">
                <input type="text" v-model="formData.BusinessContactCity" class="form-control"
                  id="formBusinessContactCityInput" />
                <span class="hint">City</span>
              </div>
              <!-- BusinessContactProvince -->
              <div class="Provinces">
                <input type="text" v-model="formData.BusinessContactProvince" class="form-control"
                  id="formBusinessContactProvinceInput" />
                <span class="hint">State/Province</span>
              </div>
              <!-- BusinessContactPostal -->
              <div class="ZIPs">
                <input type="text" v-model="formData.BusinessContactPostal" class="form-control"
                  id="formBusinessContactPostalInput" />
                <span class="hint"> ZIP/Postal</span>
              </div>
              <!-- BusinessContactCountry -->
              <div class="Country">
                <select v-model="formData.BusinessContactCountry" class="form-select" required>
                  <option v-for="(index, value) in countryList" :key="index.id">{{ index.Name }}</option>
                </select>
                <span class="hint">Country</span>
              </div>
            </div>



            <div class="mailing">
              <label for="formGroupExampleInput" class="form-label">Is the mailing address same to the Registered
                Company
                address?*</label>
              <div class="form-group  m-checkbox-inline mb-0 custom-radio-ml radiostyle">
                <div class="radio1 radio-primary">
                  <input id="radioinline_Registered" type="radio" name="radioinline_Registered"
                    @click="MailSameAsRegistered(1)" checked>
                  <label class="mb-1" for="radioinline_Registered">YES</label>
                </div>
                <div class="radio1 radio-primary">
                  <input id="radioinline2" type="radio" name="radioinline_Registered" @click="MailSameAsRegistered(0)">
                  <label class="mb-1" for="radioinline2">NO</label>
                </div>
              </div>
            </div>
            <div v-if="formData.MailingSameAsRegistered == 0" class="mailing_yes_open">
              <!-- BusinessMailingAddress -->
              <div class="mb-3">
                <label for="formBusinessMailingAddressInput" class="form-label">Business Mailing Address</label>
                <input type="text" v-model="formData.BusinessMailingAddress" class="form-control"
                  id="formBusinessMailingAddressInput" required>
                <div class="Prompt" v-if="!formData.BusinessMailingAddress && showCountryPrompt">Business Mailing
                  Address
                  is required</div>
              </div>
              <div class="ownership_three">
                <!-- BusinessMailingCity -->
                <div class="citys">
                  <input type="text" v-model="formData.BusinessMailingCity" class="form-control"
                    id="formBusinessMailingCityInput" />
                  <span class="hint">City</span>
                </div>
                <!-- BusinessMailingProvince -->
                <div class="Provinces">
                  <input type="text" v-model="formData.BusinessMailingProvince" class="form-control"
                    id="formBusinessMailingProvinceInput" />
                  <span class="hint">State/Province</span>
                </div>
                <!-- BusinessMailingPostal -->
                <div class="ZIPs">
                  <input type="text" v-model="formData.BusinessMailingPostal" class="form-control"
                    id="formBusinessMailingPostalInput" />
                  <span class="hint"> ZIP/Postal</span>
                </div>
                <!-- BusinessMailingCountry -->
                <div class="Country">
                  <select v-model="formData.BusinessMailingCountry" class="form-select" required>
                    <option v-for="(index, value) in countryList" :key="index.id">{{ index.Name }}</option>
                  </select>
                  <span class="hint">Country</span>
                </div>
              </div>
            </div>
            <div class="first_1">

              <div class="ownershipn_whole">
                <!-- BusinessPhoneNumber -->
                <div class="first_fax">
                  <label>Phone Number*</label>
                  <input type="text" v-model="formData.BusinessPhoneNumber" class="form-control inputs"
                    id="formBusinessPhoneNumberInput" />
                  <span class="hint">Please include country code and city code</span>
                  <div class="Prompt" v-if="!formData.BusinessPhoneNumber && showCountryPrompt">Phone Number is required
                  </div>
                </div>
                <!-- BusinessFaxNumber -->
                <div class="first_fax">
                  <label>Fax Number</label>
                  <input type="text" v-model="formData.BusinessFaxNumber" class="form-control inputs"
                    id="formBusinessFaxNumberInput" />
                  <span class="hint">Please include country code and city code</span>
                </div>
              </div>
            </div>
          </div>
          <div class="three title">
            <h5>Accounts Payable Contact Information</h5>
            <div class="first_column">
              <div>
                <label for="formGroupExampleInput" class="form-label">Name*</label>
                <div class="column_top">
                  <!-- PayableFirstName -->
                  <div>
                    <input type="text" v-model="formData.PayableFirstName" class="form-control"
                      id="formPayableFirstNameInput" required />
                    <span>First Name</span>
                    <div class="Prompt" v-if="!formData.PayableFirstName && showCountryPrompt">First Name is required
                    </div>
                  </div>
                  <!-- PayableLastName -->
                  <div>
                    <input type="text" v-model="formData.PayableLastName" class="form-control"
                      id="formPayableLastNameInput" required />
                    <span>Last Name</span>
                    <div class="Prompt" v-if="!formData.PayableLastName && showCountryPrompt">Last Name is required
                    </div>
                  </div>
                </div>
              </div>
              <!-- PayablePhoneNumber -->
              <div class="first_name">
                <label>Phone Number*</label>
                <input type="text" v-model="formData.PayablePhoneNumber" class="form-control inputs"
                  id="formPayablePhoneNumberInput" />
                <span class="hint">Please include country code and city code</span>
                <div class="Prompt" v-if="!formData.PayablePhoneNumber && showCountryPrompt">Phone Number is required
                </div>
              </div>
              <!-- PayableEmail -->
              <div class="first_name">
                <label>Email</label>
                <input type="text" v-model="formData.PayableEmail" class="form-control inputs"
                  id="formPayableEmailInput" />
              </div>
            </div>

            <div class="four_column">
              <!-- PayableNameofBank -->
              <div>
                <label>Name of bank used for payment*</label>
                <input type="text" v-model="formData.PayableNameofBank" class="form-control"
                  id="formPayableNameofBankInput" />
                <div class="Prompt" v-if="!formData.PayableNameofBank && showCountryPrompt">Name of bank used for
                  payment
                  is required</div>
              </div>
              <!-- PayableBankSWIFT -->
              <div>
                <label>Bank SWIFT code or identifier*</label>
                <input type="text" v-model="formData.PayableBankSWIFT" class="form-control"
                  id="formPayableBankSWIFTInput" />
                <div class="Prompt" v-if="!formData.PayableBankSWIFT && showCountryPrompt">Bank SWIFT code or identifier
                  is required</div>
              </div>
              <!-- PayableBankTel -->
              <div>
                <label>Bank's telephone number</label>
                <input type="text" v-model="formData.PayableBankTel" class="form-control"
                  id="formPayableBankTelInput" />
              </div>
            </div>
          </div>

          <div class="profile">
            <label class="form-label">Profile</label>
            <input class="form-control upload-profile" type="file" @change="updateFilePath">
            <div class="profile-area">
              <div v-for="(index, key) in formData.Profile">
                <div v-if="index.Name">
                  <a :href="getDownloadUrl(index.RealName,index.Name)" download @click="DownloadFiles(index.RealName,index.Name)" target="_blank">
                    <i class="fa fa-download"></i> {{ index.Name }} 
                  </a>
                  <i class="icofont icofont-ui-delete" @click="removeFile(key,1)"></i>
                </div>
              </div>
              <div v-for="(index, key) in formData.UploadProfile">
                <div v-if="index.name">
                    {{ index.name }} 
                    <i class="icofont icofont-ui-delete" @click="removeFile(key,0)"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="profile_btn">
            <div class="editerror" v-if="editError">{{ editError }}</div>
            <button class="btn btn-primary btn_profile" type="submit" @click="editProfile">Update Profile</button>
          </div>


        </div>
      </div>

    </form>
  </div>
</template>
<script>
  import { axiosUpdateProfile } from "@/api/userprofile"
  import { axiosCoutry } from "@/api/register"
  import axios from 'axios';

  export default {
    created() {
      this.syncCountry();

      const userlocalData = localStorage.getItem('user');
      this.userData = JSON.parse(userlocalData);
      this.formData.CompanyLegalName = this.userData.CompanyLegalName;
      this.formData.CompanyRegistration = this.userData.CompanyRegistration;
      this.formData.RegisteredCompanyAddress = this.userData.RegisteredCompanyAddress;
      this.formData.BusinessContactCity = this.userData.BusinessContactCity;
      this.formData.BusinessContactProvince = this.userData.BusinessContactProvince;
      this.formData.BusinessContactPostal = this.userData.BusinessContactPostal;
      this.formData.BusinessContactCountry = this.userData.BusinessContactCountry;
      this.formData.MailingSameAsRegistered = this.userData.MailingSameAsRegistered;
      this.formData.BusinessMailingAddress = this.userData.BusinessMailingAddress;
      this.formData.BusinessMailingCity = this.userData.BusinessMailingCity;
      this.formData.BusinessMailingProvince = this.userData.BusinessMailingProvince;
      this.formData.BusinessMailingPostal = this.userData.BusinessMailingPostal;
      this.formData.BusinessMailingCountry = this.userData.BusinessMailingCountry;
      this.formData.BusinessPhoneNumber = this.userData.BusinessPhoneNumber;
      this.formData.BusinessFaxNumber = this.userData.BusinessFaxNumber;
      this.formData.BusinessHowHearAboutUs = this.userData.BusinessHowHearAboutUs;
      this.formData.BusinessWebsiteAddress = this.userData.BusinessWebsiteAddress;
      this.formData.BusinessDescription = this.userData.BusinessDescription;
      this.formData.BusinessTypeOfBusiness = this.userData.BusinessTypeOfBusiness;
      this.formData.BusinessLocationSince = this.userData.BusinessLocationSince;
      this.formData.BusinessDateBusinessCommenced = this.userData.BusinessDateBusinessCommenced;
      this.formData.BusinessTime = this.userData.BusinessTime;
      this.formData.BusinessPrimaryTypeOfBusiness = this.userData.BusinessPrimaryTypeOfBusiness;
      this.formData.BusinessUSDollars = this.userData.BusinessUSDollars;
      this.formData.PayableFirstName = this.userData.PayableFirstName;
      this.formData.PayableLastName = this.userData.PayableLastName;
      this.formData.PayablePhoneNumber = this.userData.PayablePhoneNumber;
      this.formData.PayableEmail = this.userData.PayableEmail;
      this.formData.PayableNameofBank = this.userData.PayableNameofBank;
      this.formData.PayableBankSWIFT = this.userData.PayableBankSWIFT;
      this.formData.PayableBankTel = this.userData.PayableBankTel;
      this.formData.OwnershipFirstName = this.userData.OwnershipFirstName;
      this.formData.OwnershipLastName = this.userData.OwnershipLastName;
      this.formData.OwnershipTitle = this.userData.OwnershipTitle;
      this.formData.OwnershipAddress = this.userData.OwnershipAddress;
      this.formData.OwnershipCity = this.userData.OwnershipCity;
      this.formData.OwnershipProvince = this.userData.OwnershipProvince;
      this.formData.OwnershipPostal = this.userData.OwnershipPostal;
      this.formData.OwnershipCountry = this.userData.OwnershipCountry;
      this.formData.BusinessReferencesCompanyName = this.userData.BusinessReferencesCompanyName;
      this.formData.BusinessReferencesPhoneNumber = this.userData.BusinessReferencesPhoneNumber;
      this.formData.BusinessReferencesAddress = this.userData.BusinessReferencesAddress;
      this.formData.BusinessReferencesCity = this.userData.BusinessReferencesCity;
      this.formData.BusinessReferencesProvince = this.userData.BusinessReferencesProvince;
      this.formData.BusinessReferencesPostal = this.userData.BusinessReferencesPostal;
      this.formData.BusinessReferencesCountry = this.userData.BusinessReferencesCountry;
      this.formData.Email = this.userData.Email;
      this.formData.Profile = this.userData.Profile;

    },
    data() {
      return {
        showCountryPrompt: false,// 初始时不显示提示
        uploadFile: '',
        formData: {
          CompanyLegalName: '',
          CompanyRegistration: '',
          RegisteredCompanyAddress: '',
          BusinessContactCity: '',
          BusinessContactProvince: '',
          BusinessContactPostal: '',
          BusinessContactCountry: '',
          MailingSameAsRegistered: '',
          BusinessMailingAddress: '',
          BusinessMailingCity: '',
          BusinessMailingProvince: '',
          BusinessMailingPostal: '',
          BusinessMailingCountry: '',
          BusinessPhoneNumber: '',
          BusinessFaxNumber: '',
          BusinessHowHearAboutUs: '',
          BusinessWebsiteAddress: '',
          BusinessDescription: '',
          BusinessTypeOfBusiness: '',
          BusinessLocationSince: '',
          BusinessDateBusinessCommenced: '',
          BusinessTime: '',
          BusinessPrimaryTypeOfBusiness: '',
          BusinessUSDollars: '',
          PayableFirstName: '',
          PayableLastName: '',
          PayablePhoneNumber: '',
          PayableEmail: '',
          PayableNameofBank: '',
          PayableBankSWIFT: '',
          PayableBankTel: '',
          OwnershipFirstName: '',
          OwnershipLastName: '',
          OwnershipTitle: '',
          OwnershipAddress: '',
          OwnershipCity: '',
          OwnershipProvince: '',
          OwnershipPostal: '',
          OwnershipCountry: '',
          BusinessReferencesCompanyName: '',
          BusinessReferencesPhoneNumber: '',
          BusinessReferencesAddress: '',
          BusinessReferencesCity: '',
          BusinessReferencesProvince: '',
          BusinessReferencesPostal: '',
          BusinessReferencesCountry: '',
          Email: '',
          Profile: [],
          UploadProfile: [],
        },
        countryList: [],
        requiredData: {
          CompanyLegalName: true,
          CompanyRegistration: true,
          BusinessPhoneNumber: true,
          BusinessTypeOfBusiness: true,
          BusinessLocationSince: true,
          BusinessDateBusinessCommenced: true,
          PayableFirstName: true,
          PayableLastName: true,
          PayablePhoneNumber: true,
          PayableNameofBank: true,
          PayableBankSWIFT: true,
        },
        editError: '',
        formDatas: new FormData(),
      }
    },
    methods: {
      syncCountry() {
        axiosCoutry().then((result) => {
          this.countryList = result;
        });
      },
      validateForm() {
        let result = true;
        for (let key in this.requiredData) {
          if (this.formData[key] == undefined) {
            result = false;
          } else if (this.formData[key].trim() == '') {
            result = false;
          }
        }
        return result;
      },
      updateFilePath(event) {
        let selectedFile = event.target.files[0];
        this.formData.UploadProfile.push(selectedFile);
      },
      removeFile(key, type) {
        if(type == 0) {
          this.formData.UploadProfile.splice(key, 1);  
        } else {
          this.formData.Profile.splice(key, 1);
        }
      },
      getDownloadUrl(realName) {
        return process.env.VUE_APP_BASE_API+`/user/download?token=${this.userData.Token}&email=${this.formData.Email}&file=${realName}`;
      },
      async DownloadFiles(fileName) {
        // const realNames = [];
        // this.formData.Profile.forEach(item => {
        //   realNames.push(item.RealName);
        // });
        // console.log(realNames);

        const apiUrl = this.getDownloadUrl(fileName);
        try {
          const response = await axios.get(apiUrl, {
            responseType: 'blob'
          });
          if (response.status === true) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            console.error('下载失败');
          }
        } catch (error) {
          console.error('下载出错', error);
        }
      },
      editProfile(event) {
        event.preventDefault();

        for(var key in this.formData.UploadProfile) {
          this.formDatas.append("profile[]",  this.formData.UploadProfile[key]);
        }

        for (let key in this.formData) {
          if (this.formData.hasOwnProperty(key)) {
            if(key != 'Profile') {
              this.formDatas.append(key, this.formData[key]);   
            } else {
              for(let setIndex in this.formData[key]) {
                this.formDatas.append('file[]', this.formData[key][setIndex]['RealName']);   
              }
            }
            
          }
        }
        if (this.validateForm()) {
          axiosUpdateProfile(this.formDatas).then((result) => {
            this.profile = result;
            if (this.profile.status === true) {
              this.formDatas = new FormData(),
              localStorage.setItem('user', JSON.stringify(this.profile.data));
              this.formData.Profile = this.profile.data.Profile;
              this.formData.UploadProfile = [];
            } else {
              this.editError = this.profile.message;
            }
          });
        } else {
          this.showCountryPrompt = true;
        }
      },

      MailSameAsRegistered(Val) {
        this.formData.MailingSameAsRegistered = Val;
      }
    }
  }
</script>
<style scoped>
  .register-form input,
  .form-select {
    width: 100%;
  }

  .register-form {
    padding: 0 30px !important;
  }

  .register-form h5 {
    font-size: 16px !important;
  }

  .Prompt,
  .editerror {
    font-size: 10px;
    color: red;
    font-weight: 500;
  }

  .editerror {
    margin-top: 6vw;
  }

  input {
    text-decoration: none;
  }

  .radio1 {
    display: flex;
    width: 20px;
    margin-left: 25px;
    align-items: center;
    justify-content: center;
  }

  .radio2 input {
    width: 25px !important;
    margin-left: 5px;
  }

  .radio3 input {
    width: 15px !important;
    margin-left: 10px;
  }

  .readyEmail,
  .profile {
    margin-top: 12px;
  }

  .profile-area {
    margin: 10px;
  }



  label {
    color: rgb(31, 30, 30) !important;
    font-size: 13px !important;
    font-weight: 500 !important;

  }

  .tip {
    font-size: 10px;
    color: dimgray;
  }

  .address {
    margin-bottom: 10px;
  }

  .first_name {
    width: 10vw !important;
  }

  .first_name .inputs {
    width: 9vw !important;
  }

  .first_names {
    width: 20vw !important;
  }

  .first_names input {
    width: 18vw !important;
  }

  .first_fax {
    display: flex !important;
    width: 18vw !important;
    justify-content: space-between;
    flex-direction: column;
  }

  .first_fax input {
    width: 17.5vw !important;
  }

  .btn_submit {
    text-transform: uppercase;
    font-weight: 700;
    width: 150px;
    height: 40px;
    border-radius: 15px;
    margin: 4vw 32vw;
    border: 0;
    color: #ffffff;
    margin-bottom: 2vw !important;
  }

  .info {
    margin-bottom: 1vw;
    color: #353434;
  }

  .ownershipn_whole {
    margin-top: 12px;
  }


  .mailing,
  .mailing_yes_open {
    margin-top: 16px;
  }


  .citys {
    width: 9.5vw !important;
  }

  .citys input,
  .Provinces input {
    width: 8vw !important;
  }

  .Provinces,
  .ZIPs {
    width: 9.5vw !important;
  }

  .Country {
    width: 10vw !important;
  }

  .Country select,
  .business_Address input {
    width: 9.5vw !important;
  }

  .ZIPs input {
    width: 8vw !important;
  }

  .business_methods {
    width: 12.5vw !important;
  }

  .business_methods input {
    width: 12.5vw !important;
  }

  .business_date,
  .business_primary,
  .business_dollars {
    margin-top: 16px !important;
  }


  .first_column {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .first_column label,
  .first_name label {
    height: 2vw;
  }

  .column_top {
    display: flex;
  }

  .column_top input {
    width: 8vw !important;
    margin-right: 10px;
  }

  .column_top span {
    font-size: 8px;
    color: darkgrey;
  }

  .second_column {
    margin-bottom: 10px;
  }

  .three_column {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: black;
    margin-top: 5px;
  }

  .three_column .artitle {
    width: 80vw;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .three_column .arthead {
    width: 80vw;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .four_column {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vw;
  }


  .four_column label {
    color: #5e5d5d;
  }

  .four_column input {
    width: 11.3vw !important;
    margin-right: 11px;
  }

  .reference_next {
    width: 24vw !important;
    margin-top: 16px;
  }

  .reference_next input {
    width: 22vw !important;
  }

  .layout {
    width: 14vw !important;
  }


  .ownershipn_whole {
    display: flex;
    justify-content: space-between;
  }

  .first_name input {
    width: 15vw !important;
  }

  .Countrys {
    margin-top: 16px;
  }

  .Countrys select {
    width: 82vw !important;
  }

  .first_name1 input {
    width: 10vw !important;
    margin-right: 11px;
  }

  .first_name2 input {
    width: 40vw !important;
  }

  .first_style {
    width: 18vw !important;
  }

  .first_style input {
    width: 24vw !important;
  }

  .first_title {
    width: 40vw !important;
  }

  .first_title2 {
    width: 25vw !important;
  }

  .first_2 input {
    margin-top: 12px;
    width: 16.1vw !important;
  }

  .hint {
    font-size: 8px;
    color: darkgrey;
  }

  .radio1 {
    display: flex;
    width: 20px;
    margin-left: 25px;
    align-items: center;
    justify-content: center;
  }

  .mb-1 {
    margin-left: 14px;
  }

  .ownership_three {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    height: 4vw;
  }

  .city input {
    width: 8.5vw !important;
    margin-right: 10px;
  }

  .Province input {
    width: 8.5vw !important;
    margin-right: 10px;
  }

  .ZIP input {
    width: 9.5vw !important;
    margin-right: 10px;
  }

  .subtitle {
    margin-top: -4px;
  }

  .shape {
    width: 40vw !important;
  }

  .profile {
    margin-top: 20px;
  }

  .profile_btn {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }

  .btn_profile {
    margin-top: 4vw !important;
  }



  @media(max-width:900px) {

    .register-form input,
    .form-select {
      width: 107%;
    }

    .ownership_three {
      width: 107%;
    }

    .Country {
      width: 16vw !important;
    }

    .citys input,
    .Provinces input,
    .ZIPs input,
    .Country select {
      width: 16vw !important;
    }

    .mailing {
      margin-top: 10vw;
    }

    .first_fax {
      width: 30vw !important;
    }

    .first_fax input {
      width: 28vw !important;
    }

    .ZIPs {
      width: 11.5vw !important;
    }

    .Provinces {
      width: 14.5vw !important;
    }

    .business_methods {
      width: 23.5vw !important;
    }

    .first_column label,
    .first_name label {
      height: 3vw !important;
    }

    .column_top input {
      width: 16vw !important;
    }

    .first_name,
    .first_name .inputs,
    .first_name1 input {
      width: 17vw !important;
    }

    .four_column input {
      width: 20vw !important;
    }

    .first_2 input {
      width: 47vw !important;
    }


    .first_names {
      width: 41vw !important;
    }

    .first_names input {
      width: 38vw !important;
    }

    .city input,
    .Province input,
    .ZIP input {
      width: 16vw !important;
    }

    .profile {
      margin-top: 20px;
    }

    .profile_btn {
      margin-top: 4vw;
    }
  }

  @media(max-width:700px) {

    .first_2 input {
      width: 40vw !important;
    }

    .first_column label {
      height: 5.5vw !important;
    }

    .first_name label {
      height: 6vw !important;
    }

    .profile {
      margin-top: 55px;
    }

    .mailing {
      margin-top: 10vw;
    }
  }

  @media(max-width:500px) {

    .four_column label {
      height: 21vw !important;
    }

    .first_fax {
      width: 30.5vw !important;
    }

    .first_fax input {
      width: 33vw !important;
    }

    .mailing {
      margin-top: 15vw !important;
    }

    .first_column label {
      height: 8.4vw !important;
    }

    .first_2 input {
      width: 30vw !important;
    }

    .btn_profile {
      margin-top: 17vw !important;
    }

    .four_column input {
      width: 21.5vw !important;
    }

    .first_names {
      width: 36vw !important;
    }

    .first_names input {
      width: 34vw !important;
    }

    .profile_btn {
      display: flex;
      align-items: flex-start;
    }

    .register-form .btn {
      text-transform: none !important;
      width: 100%;
    }

    .city input,
    .Province input,
    .ZIP input {
      width: 14vw !important;
    }

    .Province {
      width: 15vw;
    }

    .first_fax label {
      height: 9vw;
    }


  }
</style>