import {get,post,put,del} from "@/model/http"

export function axiosAddAlert(data) {
    return post(`/api/v1/alert`, data);
}

export function axiosUpdateAlert(data) {
    return put(`/api/v1/alert`, data);
}

export function axiosDelAlert(data) {
    return del(`/api/v1/alert`, data);
}

export function axiosGetAlert(data) {
    return get(`/api/v1/alert`, data);
}
