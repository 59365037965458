import {put,post,patch} from "@/model/http"

export function axiosUpdateProfile(data) {
    return post(`/api/v1/user/accountprofile`, data);
}

export function axiosUpdatePasswordProfile(data) {
    return patch(`/api/v1/user/accountprofile/password`, data);
}

export function axiosResetPasswordProfile(data) {
    return post(`/api/v1/user/resetpassword`, data);
}