<template>
  <div class="col-xl-4">
    <form @submit="updateProfiles">
      <div class="card">
        <div class="card-header pb-0">
          <h4 class="card-title mb-0">My Profile</h4>
          <div class="card-options"><a class="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i
                class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#" data-bs-toggle="card-remove"><i
                class="fe fe-x"></i></a></div>
        </div>
        <div class="card-body">
          <form>
            <div class="row mb-2">
              <div class="profile-title">
                <div class="media">
                  <!-- <img class="img-70 rounded-circle" alt="" src="../../../assets/images/user/7.jpg"> -->
                  <div class="media-body">
                    <h3 class="mb-2 f-20 txt-primary">{{ BusinessReferencesCompanyName }}</h3>
                    <p class="f-12 font-roboto">{{ fullName }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label">Email-Address</label>
              <input class="form-control" v-model="Email" placeholder="your-email@domain.com" type="email" disabled>
            </div>
            <!-- <div class="mb-3">
              <label class="form-label" for="newPassword">Password</label>
              <input class="form-control" id="newPassword" type="password" v-model="newPassword" placeholder="********">
            </div>
            <div class="mb-3">
              <label class="form-label" for="confirmPassword">Confirm Password</label>
              <input class="form-control" id="confirmPassword" type="password" v-model="confirmPassword"
                placeholder="********">
            </div> -->
            <div class="form-group">
              <label>Password</label>
              <div class="input-group"><span class="input-group-text"><i class="icon-lock"></i></span>
                <input class="form-control" :type="type" name="updateProfiles[confirmPassword]"
                  v-model="userData.newPassword" required="" placeholder="*********"
                  >
                <div class="show-hide"><span class="show" @click="showPassword"></span></div>
              </div>
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <div class="input-group"><span class="input-group-text"><i class="icon-lock"></i></span>
                <input class="form-control" :type="type" name="updateProfiles[confirmPassword]" required=""
                  placeholder="*********" v-model="userData.confirmPassword">
              </div>
            </div>
            <div v-if="errormessage" class="errorInfo">Password and Confirm Password do not match</div>
            <div v-if="errorpassword" class="errorInfo">Password more than 8 characters</div>
            <div v-if="errorresult" class="errorInfo">{{errorresult}}</div>
            <button class="btn btn-primary btn_updateProfile" @click="updateProfiles">Save</button>
          </form>
        </div>
      </div>
    </form>

  </div>
</template>
<script>
import { axiosUpdatePasswordProfile } from "@/api/userprofile"
export default {
  name: 'myProfile',
  data() {
    return {
      type: 'password',
      userData: {
        fullName: '',
        BusinessReferencesCompanyName: '',
        Email: '',
        newPassword: '',
        confirmPassword: '',
      },
      errorresult: false,
      errormessage: false,
      errorpassword: false

    };
  },
  created() {

    // 从本地存储中获取数据并赋值给组件的 data 属性
    const userlocalData = localStorage.getItem('user');
    this.userData = JSON.parse(userlocalData);
    const PayableFirstName = this.userData.PayableFirstName;
    const PayableLastName = this.userData.PayableLastName;
    this.fullName = `${PayableFirstName}  ${PayableLastName}`;
    this.BusinessReferencesCompanyName = this.userData.BusinessReferencesCompanyName;
    this.Email = this.userData.Email;
  },

  methods: {
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    theFormat(number) {
      return number.toFixed(1);
    },
    updateProfiles(event) {
      event.preventDefault();
      if (this.userData.newPassword === this.userData.confirmPassword) {
        if (this.userData.newPassword && this.userData.newPassword.length > 8) {
          axiosUpdatePasswordProfile({ password: this.userData.newPassword }).then((result) => {
            this.passwordProfile = result;
            if(this.passwordProfile.status === true){
              this.$router.push('/auth/login');
            } else {
              this.errorresult = this.passwordProfile.message;
            }
          });
        }else{
          this.errorpassword=true;
          this.errormessage=false;
        }
      } else {
        this.errormessage = true;
        this.errorpassword=false;
      }
    }
  },
};
</script>
<style scoped>
.btn_updateProfile {
  margin-left: 60%;
}

.errorInfo {
  font-size: 12px;
  color: red;
  font-weight: 500;
}
@media(max-width:700px) {
  .font-roboto{
    margin-left: 1%;
  }
}
@media(max-width:480px) {
  .font-roboto{
    margin-left: 4px;
  }
  .btn_updateProfile{
    margin-left: 10% !important;
    width: 80% !important;
  }
}
</style>